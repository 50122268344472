import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Button, Placeholder, Table } from 'react-bootstrap';
import Utils from "../../configs/Utils";
import StripeAccountListItem from "./StripeAccountListItem";
import Enums from "../../configs/Enums";


const StripeAccountList = ({...props}) => {
  const [cookie] = useCookies(['']);
  const [loading, setLoading] = useState(true);
  const {accountList, setAccountList, updatingAccount, setUpdatingAccount} = props;

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        const data = await Utils._apiRequest({
          path: Enums.endpoints.stripe.ACCOUNT,
          type: 'GET',
          cookie
        });
        setAccountList(data);
        setLoading(false);
      } catch(err) {
        console.log(err.message);
      }
    }

    fetchAuth();
  }, [cookie, setAccountList, setLoading]);

  const mdRand = (min, max) => {
    return Math.round(Math.random() * (max - min) + min);
  }

  return (
    <Table striped hover className="text-nowrap">
      <caption>List of Stripe Accounts</caption>
      <thead>
        <tr>
          <th width={20}>#</th>
          <th width={180}>Account Name</th>
          <th width={200}>Account</th>
          <th width={40}>Status</th>
          <th>Domain</th>
          <th width={150}>Payout</th>
          <th width={30}></th>
        </tr>
      </thead>
      <tbody>
        { accountList.length > 0 ? (
          accountList.map((account, i) => {
            return (
              <StripeAccountListItem 
                key={account._id} 
                account={account} 
                index={i} 
                accountList={accountList} 
                setAccountList={setAccountList} 
                updatingAccount={updatingAccount}
                setUpdatingAccount={setUpdatingAccount} 
              />
            )
          })
        ) : (
          <>
          { loading ? (
            <>
            { [1,2,3,4].map((i) => {
            return (
              <tr key={i} className="placeholder-group">
                <td>{i}</td>
                <td>
                  <Placeholder animation="glow">
                    <Placeholder xs={mdRand(10, 12)} />
                  </Placeholder>
                </td>
                <td>
                  <Placeholder animation="glow">
                    <Placeholder xs={mdRand(10, 12)} />
                  </Placeholder>
                </td>
                <td>
                  <Placeholder animation="glow">
                    <Placeholder xs={12} />
                  </Placeholder>
                </td>
                <td>
                  <Placeholder animation="glow">
                    <Placeholder xs={mdRand(7, 12)} />
                  </Placeholder>
                </td>
                <td>
                  <Placeholder animation="glow">
                    <Placeholder xs={10} />
                  </Placeholder>
                </td>
                <td className="text-inline text-end">
                  <Button className="ms-1" size="xs" disabled variant="secondary" title="Edit"><FontAwesomeIcon icon={faPen} /></Button>
                  <Button className="ms-1" size="xs" disabled variant="danger" title="Edit"><FontAwesomeIcon icon={faTrashCan} /></Button>
                </td>
              </tr>
            )
          }) }
            </>
          ) : (
            <>
            <tr>
              <td colSpan={7}>Nothing to show...</td>
            </tr>
            </>
          ) }
          </>
        ) }
        
      </tbody>
    </Table>
  );
}

export default StripeAccountList;
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import Utils from '../../configs/Utils';
import Enums from '../../configs/Enums';
import { Button, Placeholder } from 'react-bootstrap';

const AccountActivatePage = () => {
  const { id } = useParams();
  const { currentUser, setCurrentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id) {
      const fetchActivateAccount = async () => {
        try {
          const data = await Utils._apiRequest({
            path: Enums.endpoints.auth.ACTIVATE,
            pathParams: { token: id },
            type: 'GET'
          });

          if (!data.user && data.message) {
            throw new Error(data.message);
          }
          setCurrentUser(data.user);
        } catch(error) {
          alert(error.message);
          console.log(error);
        }

        setIsLoading(false);
      }

      fetchActivateAccount();
    } else {
      if (currentUser?.status) {
        setIsLoading(false);
      }
    }
  }, [id, setCurrentUser, currentUser]);

  return (
    <main className='main-page'>
      { isLoading ? (
        <div className='container mt-5 bg-body-tertiary p-5 rounded shadow' style={{ maxWidth: "800px", fontSize: "1.2em" }}>
          <Placeholder animation="glow" as="h2" className="border-boborder-bottom pb-2 mb-3">
            <Placeholder xs={9} />
          </Placeholder>
          <Placeholder animation="glow" as="p">
            <Placeholder xs={12} />{' '}
            <Placeholder xs={4} />
          </Placeholder>
          <Placeholder animation="glow" as="p">
            <Placeholder xs={8} />
          </Placeholder>
          <Placeholder animation="glow" as="p">
            <Placeholder.Button variant="primary" xs={3} />
          </Placeholder>
        </div>
      ) : (
        <>
        { currentUser?.status === 'activated' ? (
          <div className='container mt-5 bg-body-tertiary p-5 rounded shadow' style={{ maxWidth: "800px", fontSize: "1.2em" }}>
            <h2 className='border-boborder-bottom pb-2 mb-3'>Your account has been successfully activated! 🎉</h2>
            <p>You can now enjoy full access to all our features. Thank you for verifying your email. To get started, log in with your credentials.</p>
            <p>If you have any questions, feel free to contact our support team. Welcome aboard!</p>
            <Button href='/' variant='dark'>🎉 Welcome aboard!</Button>
          </div>
        ) : (
          <div className='container mt-5 bg-body-tertiary p-5 rounded shadow' style={{ maxWidth: "800px", fontSize: "1.2em" }}>
            <h2 className='border-boborder-bottom pb-2 mb-3'>Activate Your Account</h2>
            <p>Thank you for registering! 🎉</p>
            <p>To start using your account, please activate it by clicking the confirmation link we sent to your email.</p>
            <h5 className='mt-4'>Haven&lsquo;t received the email?</h5>
            <ul>
              <li>Check your spam or junk folder.</li>
              <li>If it&lsquo;s not there, try resending the email by clicking the button below.</li>
            </ul>
            <p>We&lsquo;re excited to have you with us! 😊</p>
          </div>
        ) }
        </>
      ) }
    </main>
  );
}



export default AccountActivatePage;
import React from "react";
import { Image } from "react-bootstrap";
import StripeAccounts from "../../Components/StripeAccounts";
import Utils from "../../configs/Utils";

const Gateways: React.FC = () => {
  return (
    <main className='page-main'>
      <div className="main-container container">
        <div className="d-flex align-items-center p-3 my-3 text-white bg-primary rounded shadow-sm heading-box">
          <Image src={Utils.getPublicImage('/assets/images/stripe-logo.png')} width={38} className="me-3" alt="Stripe heading img" />
          <div className="lh-1">
            <h2 className="h6 mb-0 text-white lh-1">Stripe</h2>
            <small>Manage Accounts, Settings, ...</small>
          </div>
        </div>

        <StripeAccounts />
      </div>
    </main>
  )
}

export default Gateways;
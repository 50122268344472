import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { FloatingLabel, Image, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { useAuth } from "../contexts/AuthContext";
import Utils from "../configs/Utils";
import Enums from "../configs/Enums";

// Import styling
import "../assets/css/account.scss";

const Register: React.FC = () => {
  const userRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [, setCookie] = useCookies(['token']);
  const { setCurrentUser } = useAuth();

  const [email, setEmail] = useState<string>('');
  const [emailErrMsg, setEmailErrMsg] = useState<string>('');
  const emailErrHandle = (value: string) => {
    if (emailErrMsg.length === 0) return;
    const validEmail = Enums.Regex.EMAIL.test(value);
    if (validEmail) {
      setEmailErrMsg(''); // Please enter a valid email!
    }
  }

  const [password, setPassword] = useState<string>('');
  const [passwordErrMsg, setPasswordErrMsg] = useState<string>('');
  const passwordErrHandle = (value: string) => {
    if (passwordErrMsg.length === 0) return;
    const validPass = Enums.Regex.PASS.test(value);
    if (validPass) {
      setPasswordErrMsg('');
    }
  }

  const [fullName, setFullName] = useState<string>('');
  const [fullNameErrMsg, setFullNameErrMsg] = useState<string>('');
  const fullNameErrHandle = (value: string) => {
    if (fullNameErrMsg.length === 0) return;
    const validName = Enums.Regex.NAME.test(value);
    if (validName) {
      setFullNameErrMsg('');
    }
  }

  useEffect(() => {
    userRef.current?.focus();
    Utils.setPageTitle('Register page');
  }, []);

  const validFields = () => {
    // valide Email
    if (email.length === 0) {
      setEmailErrMsg("This field is required!");
    } else if (!Enums.Regex.EMAIL.test(email)) {
      setEmailErrMsg('Please enter a valid email!');
    }

    // valide fullname
    if (fullName.length === 0) {
      setFullNameErrMsg("This field is required!");
    } else if (!Enums.Regex.NAME.test(fullName)) {
      setFullNameErrMsg('The special characters is not allowed!');
    }

    // valide Password
    if (password.length === 0) {
      setPasswordErrMsg("This field is required!");
    } else if (!Enums.Regex.PASS.test(password)) {
      setPasswordErrMsg('Please enter a stronger password!');
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // validate fields
    validFields();
    if (emailErrMsg === '' && fullNameErrMsg === '' && passwordErrMsg === '') {
      setLoading(true);
      // Send register request
      const data = await Utils._apiRequest({
        path: Enums.endpoints.auth.SIGNUP as string,
        type: 'POST',
        payload: { email, password, fullName }
      });

      if (data.errors) {
        if (data.errors.email !== "") {
          setEmailErrMsg(data.errors.email);
        }
        if (data.errors.password !== "") {
          setPasswordErrMsg(data.errors.password);
        }
        if (data.errors.fullName !== "") {
          setFullNameErrMsg(data.errors.fullName);
        }
      } else {
        // Set cookie
        setCookie("token", "Bearer " + data.token, { path: "/", expires: new Date(data.tokenExp * 1000), secure: true });
        setCurrentUser(data);
        window.location.href = '/';
      }
    }
  }

  return (
    <main className="page-main account-page container">
      <section className="register-wrapper">
        <form onSubmit={handleSubmit} className={`p-4 p-md-5 border rounded-3 bg-body-tertiary${loading ? ' loading' : ''}`}>
          <h1 className="logo text-center">
            <Image src={Utils.getPublicImage('/assets/images/logo-main.png')} width={145} alt="" />
          </h1>

          {loading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <></>
          )}

          <FloatingLabel
            controlId="emailFloatingInput"
            label="Email address"
            className="mb-3"
          >
            <Form.Control
              ref={userRef}
              type="text"
              className={emailErrMsg !== "" ? " is-invalid" : ""}
              placeholder="name@example.com"
              onChange={(e) => { setEmail(e.target.value); emailErrHandle(e.target.value) }}
            />
            {emailErrMsg !== "" ? <p className="invalid-feedback">{emailErrMsg}</p> : null}
          </FloatingLabel>

          <FloatingLabel
            controlId="fullNameFloatingInput"
            label="Full Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              className={fullNameErrMsg !== "" ? " is-invalid" : ""}
              onChange={(e) => { setFullName(e.target.value); fullNameErrHandle(e.target.value) }}
              placeholder="Jason N"
            />
            {fullNameErrMsg !== "" ? <p className="invalid-feedback">{fullNameErrMsg}</p> : null}
          </FloatingLabel>

          <FloatingLabel
            controlId="passwordFloatingInput"
            label="Password"
            className="mb-3"
          >
            <Form.Control
              type="password"
              className={passwordErrMsg !== "" ? " is-invalid" : ""}
              onChange={(e) => { setPassword(e.target.value); passwordErrHandle(e.target.value) }}
              autoComplete="false"
              placeholder="password"
            />
            {passwordErrMsg !== "" ? <p className="invalid-feedback">{passwordErrMsg}</p> : null}
          </FloatingLabel>

          <button className="w-100 btn btn-lg btn-primary">Sign Up</button>

          <div className="footer">
            <p className="mb-1">You do have an account? <Link to="/login">Login</Link></p>
            <p>By continuing, you agree to our <Link to="/policy">Privacy Policy</Link>.</p>
          </div>
        </form>
      </section>
    </main>
  )
}

export default Register;
import React, { createContext, useState, useEffect, useContext, ReactNode } from "react";
import { useCookies } from "react-cookie";
import PropTypes from "prop-types";
import Utils from "../configs/Utils";
import Enums from "../configs/Enums";
import { User } from "../Models/User";

// Define the shape of the AuthContext
interface AuthContextType {
  currentUser: User | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>
}

// Create AuthContext to hold authentication state and actions
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthContextProviderProps {
  children: ReactNode;
}

// Provider component to supply authentication-related data and actions to child components
export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
  // Define state variables
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [cookie, , removeCookie] = useCookies(['token', 'login_redirect']);

  // Fetch authenticated user data if a valid token is present in cookies
  useEffect(() => {
    const fetchAuth = async () => {
      try {
        const data = await Utils._apiRequest({
          path: Enums.endpoints.auth.ME as string,
          type: 'GET',
          cookie
        });
        if (data.errors) {
          // Remove token if errors occur
          removeCookie('token', { path: '/' });
          setCurrentUser(null);
        } else {
          // Set user data upon successful authentication
          setCurrentUser(data);
        }
      } catch (err) {
        console.log('request Auth error: ', err);
      }
    }

    // Fetch authentication data only if token exists and current user data is empty
    if (cookie && cookie.token && (!currentUser || Object.keys(currentUser).length === 0)) {
      fetchAuth();
    }

  }, [cookie, removeCookie, currentUser]);

  // Redirect user after login if `login_redirect` cookie is set
  useEffect(() => {
    if (cookie?.token && cookie?.login_redirect) {
      const login_redirect = cookie.login_redirect;
      removeCookie('login_redirect', { path: '/' });
      window.location.href = login_redirect;
    }
  }, [cookie, removeCookie]);

  // Provide `currentUser`, `setCurrentUser` to child components
  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
      { children }
    </AuthContext.Provider>
  );
}

AuthContextProvider.prototype = {
  children: PropTypes.element.isRequired,
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }
  return context;
}
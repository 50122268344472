import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Utils from "../../configs/Utils";
import { useCookies } from "react-cookie";
import Enums from "../../configs/Enums";

const OrderFilterForm = ({...props}) => {
  const { setFilter } = props;
  const [params, setParams] = useState({});
  const [stores, setStores] = useState([]);
  const [cookie] = useCookies();

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const data = await Utils._apiRequest({
          path: Enums.endpoints.store.WOO,
          type: 'GET',
          cookie
        });
        setStores(data);
      } catch(err) {
        console.log(err.message);
      }
    }

    if (!stores.length) {
      // fetchStores();
    }
  }, []);

  const hanndleSearchForm = (e) => {
    e.preventDefault();
    let data = params;
    data[e.target.name] = e.target.value;
    setParams(data);
  }

  const hanndleSearchFormSubmit = async (e) => {
    e.preventDefault();
    let prs = {};
    Object.keys(params).map(key => {
      if (params[key].length > 0) {
        prs[key] = params[key];
      }
    });
    setFilter(prs);
  }

  return (
    <Form className="mb-0" onChange={hanndleSearchForm} onSubmit={hanndleSearchFormSubmit}>
      <div className="d-flex gap-2 align-items-center">
        <Col md={3}>
          <Form.Control name="s" size="sm" type="search" placeholder="Order number, Custom name, email..." />
        </Col>
        <Col md="auto">
          <Form.Select size="sm" name="status" aria-label="Filter by status">
            <option value=''>All</option>
            <option value="processing">Processing</option>
            <option value="completed">Completed</option>
            <option value="failed">Failed</option>
          </Form.Select>
        </Col>
        <Col md="auto">
          <Form.Select size="sm" name="store" aria-label="Filter by Merchant">
            <option value=''>All Stores</option>
            { stores.length ? stores.map((store) => {
              return (
                <option key={store._id} value={store._id}>{store.storeName}</option>
              )
            }) : (
              <></>
            ) }
          </Form.Select>
        </Col>
        <Col md="auto">
              <Button type="submit" size="sm" variant="dark">Apply</Button>
        </Col>
      </div>
    </Form>
  );
}

export default OrderFilterForm;
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Utils from "../../configs/Utils";
import Enums from "../../configs/Enums";
import { Badge, Col, Placeholder, Row } from "react-bootstrap";

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/styles/overlayscrollbars.css';

interface StoreStatProps {
  _id: string;
  countOrder: number;
  flatform: string;
  status: string;
  storeFriendlyName: string;
  storeName: string;
  storeId: string;
  userDetails: {
    email: string;
    fullName: string;
  }
}

const StoreByOrderCount: React.FC = () => {
  const [cookie] = useCookies(['token']);
  const [stores, setStores] = useState<StoreStatProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchSeller = async () => {
      try {
        const response: StoreStatProps[] = await Utils._apiRequest({
          path: Enums.endpoints.stats.STORES_ORDER_COUNT as string,
          type: 'GET',
          cookie
        });
        setStores(response);
        setLoading(false);
      } catch(error) {
        console.log((error as Error).message);
      }
    }

    if (cookie.token) {
      fetchSeller();
    }
  }, [cookie]);

  return (
    <div className="my-3 p-3 bg-body rounded shadow feature-box">
      <h6 className="heading border-bottom pb-2 mb-3">Stores</h6>
      
      <OverlayScrollbarsComponent 
        style={{ maxHeight: '400px' }}
        options={{ scrollbars: { autoHide: 'scroll' } }}
      >
        <div className="list-group list-group-flush border-bottom scrollarea">
          { loading ? [1,2,3,4,5,6].map(i => {
            return (
              <a href="#" className="list-group-item list-group-item-action d-flex shadow-sm gap-3 p-3 mb-3 rounded" style={{background: "#f0f0f0"}}>
                <div
                  style={{
                    width: '24px',
                    height: '22px',
                    backgroundColor: '#666',
                    borderRadius: '50em',
                  }}
                  className="mb-3"
                ></div>
                <div className="d-flex gap-2 w-100 justify-content-between">
                  <div style={{ width: '250px'}}>
                    <Placeholder as="h6" animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder as="p" animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  </div>
                  <strong className="text-body-secondary" style={{width: '50px'}}>
                    <Placeholder as="strong" animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  </strong>
                </div>
              </a>
            )
          }) 
          : stores.map(store => {
            return (
              <a href="#" className="list-group-item list-group-item-action py-3 ps-0 lh-sm">
                <div className="d-flex gap-2 w-100 justify-content-between align-items-start">
                  <div style={{maxWidth: "calc(100% - 50px)"}}>
                    <h6 className="mb-2 text-capitalize text-overflow" title={ store.storeFriendlyName }>{ store.storeFriendlyName }</h6>
                    <Badge bg="secondary">Seller: { store.userDetails.fullName }</Badge>
                  </div>
                  <span className="badge text-bg-primary rounded-pill">{store.countOrder}</span>
                </div>
              </a>
            )
            })
          }
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}

export default StoreByOrderCount;
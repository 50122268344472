import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogleDrive, faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faSliders } from "@fortawesome/free-solid-svg-icons";

import { useAuth } from "../../contexts/AuthContext";
import Utils from "../../configs/Utils";
import Enums from "../../configs/Enums";
import SheetsSettingsTab from "../../Components/Settings/sheetsSettingsTab";

// Styling
import "./style.scss";
import { Option } from "../../Models/Option";
import { Button, Col, Form, InputGroup, Nav, Row, Tab } from "react-bootstrap";
import CopyButton from "../../Components/Common/CopyButton";

interface Oath2Token {
  [key: string]: any;
}

interface StoreOptionProps {
  slug: string | number;
  stores: StoreItemProps[];
}

interface StoreItemProps {
  label: String;
  id: String;
}

const SettingsPage: React.FC = () => {
  const { currentUser } = useAuth();
  const [activedTab, setActiveTab] = useState<string>('Google Sheets');
  const [oath2Token, setOath2Token] = useState<Oath2Token>({});
  const [settings, setSettings] = useState<Option[]>([]);
  const [cookie] = useCookies(['token']);

  const [storeOptions, setStoreOptions] = useState<StoreItemProps[]>([]);
  const [selectedValues, setSelectedValues] = useState<StoreOptionProps[]>([{slug: 1, stores: []}]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    Utils.setPageTitle('Settings page');

    const fetchWooStores = async () => {
      setLoading(true);
      const storeRes = await Utils._apiRequest({
        path: Enums.endpoints.store.SEARCH as string,
        type: 'GET',
        cookie
      });
      
      setStoreOptions(storeRes.map((item: any) => ({ label: item.storeFriendlyName, id: item._id })));
      setLoading(false);
    }

    fetchWooStores();
  }, []);

  // Get google token from user
  useEffect(() => {
    if (currentUser?.meta?.oath2Token) {
      setOath2Token(currentUser.meta.oath2Token);
    }
  }, [currentUser]);

  // Fetch to get settings
  useEffect(() => {
    const fetchOptions = async () => {
      const optionsRes = await Utils._apiRequest({
        path: Enums.endpoints.options.LIST as string,
        type: 'GET',
        cookie
      });

      if (optionsRes.data?.length) {
        const setttings = optionsRes.data.filter((item: Option) => {
          return item.type === "setting";
        });
        setSettings(setttings);
      }
    }
    fetchOptions();
  }, [setSettings]);

  const selectOptionHandle = (index: number, stores: StoreItemProps[]) => {
    let newData = selectedValues.slice();
    newData[index].stores = stores;
    setSelectedValues(newData);
  }

  const addMoreGroup = () => {
    let newData = selectedValues.slice();
    newData.push({ slug: newData.length, stores: [] });
    setSelectedValues(newData);
  }

  useEffect(() => {
    if (settings.length) {
      const telebot = settings.find(item => item.key === `TELEBOT-stores-${currentUser?._id}`);
      if (telebot) {
        setSelectedValues(telebot.values);
      }
    }
  }, [settings]);

  const handleSaveTeleStoresOptions = async () => {
    setLoading(true);
    const payload = {
      key: `TELEBOT-stores-${currentUser?._id}`,
      group: "telebot_stores",
      values: selectedValues
    };
    const optionResponse = await Utils._apiRequest({
      path: Enums.endpoints.options.SAVE as string,
      type: 'POST',
      payload: payload,
      cookie
    });
    setLoading(false);
  }

  const handleUpdateSlug = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    let slug = e.target.value;
    if(slug.length === 0) return false;
    slug = slug.replace(/[^a-zA-Z0-9]/g, '');
    let newData = selectedValues.slice();
    newData[i].slug = slug;
    setSelectedValues(newData);
  }

  return (
    <main className='page-main'>
      <div className="main-container container">
        <div className="d-flex align-items-center p-3 my-3 text-white bg-primary rounded shadow-sm heading-box">
          <FontAwesomeIcon className="me-3" fixedWidth size="xl" icon={faSliders} />
          <div className="lh-1">
            <h2 className="h6 mb-0 text-white lh-1">Settings Page</h2>
            <small>Google Sheets, ...</small>
          </div>
        </div>
        <div className="my-3 p-3 border rounded-3 bg-body shadow-sm feature-box">
          <h6 className="heading border-bottom pb-2 mb-3">{ activedTab }</h6>
          <Tab.Container id="settings-tabs" defaultActiveKey="google-sheet">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column settings-tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="google-sheet" onClick={() => setActiveTab('Google sheets')}  className="btn btn-hover-light rounded-2 d-flex align-items-start py-2 px-3 lh-sm text-start">
                      <FontAwesomeIcon icon={faGoogleDrive} size="lg" />
                      <div>
                        <strong className="d-block">Google sheets</strong>
                        <small><i>Manage the linked Google sheets files.</i></small>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="telegram-report" onClick={() => setActiveTab('Telegram report')} className="btn btn-hover-light rounded-2 d-flex align-items-start py-2 px-3 lh-sm text-start">
                      <FontAwesomeIcon icon={faTelegram} size="lg" />
                      <div>
                        <strong className="d-block">Telegram report</strong>
                        <small><i>Manage WooCommerce reports via Telegram BOT</i></small>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="google-sheet">
                    <SheetsSettingsTab oath2Token={oath2Token} settings={settings} setOath2Token={setOath2Token} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="telegram-report">
                    <Form>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3"><strong>Auth Command</strong></Form.Label>
                        <Col sm="9">
                          <CopyButton textToCopy={ `/dreamteam auth ${currentUser?._id}` }> <small><i>Telegram command</i>:</small> <code>/dreamteam auth {currentUser?._id}</code></CopyButton>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3"><strong>Select store for group</strong></Form.Label>
                        <Col sm="9">
                          { selectedValues.map((item, i) => {
                            console.log(selectedValues);
                            const selectedData = selectedValues[i].stores;
                            const slug = selectedValues[i].slug;
                            
                            return (
                              <div key={i}>
                              <InputGroup className="mb-3">
                                <InputGroup.Text>
                                  <small><i>Telegram command:</i></small> <code>/dreamteam report</code>
                                </InputGroup.Text>
                                <Form.Control id={ `telebot.slug.${i}` } value={ slug } onChange={ (e: React.ChangeEvent<HTMLInputElement>) => { handleUpdateSlug(e, i) } } />
                              </InputGroup>

                              <Autocomplete
                                className="mb-4"
                                multiple
                                disabled={loading}
                                options={storeOptions}
                                value={selectedData}
                                onChange={(event: React.SyntheticEvent, newValue: StoreItemProps[]) => { selectOptionHandle(i, newValue) }}
                                getOptionLabel={(option: StoreItemProps) => option.label as string} 
                                isOptionEqualToValue={(option: StoreItemProps, value: StoreItemProps) => option.id === value.id} // unique
                                loading={loading}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label={ `Group ${i+1}` }
                                    placeholder="Select..."
                                  />
                                )}
                              />
                              </div>
                            );
                          }) }
                          
                          <div className="text-end">
                          <Button variant="secondary" disabled={loading} className="me-2" onClick={ () => { addMoreGroup() } }>Add more group</Button>
                          <Button variant="primary" disabled={loading} onClick={ handleSaveTeleStoresOptions }>Save</Button>
                          </div>
                        </Col>
                        
                      </Form.Group>
                    </Form>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </main>
  );
};

export default SettingsPage;
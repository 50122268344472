import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthContextProvider } from './contexts/AuthContext';
import { CookiesProvider } from "react-cookie";
import AppRouter from './Routes/AppRouter';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider>
    <AuthContextProvider>
      <React.StrictMode>
        <AppRouter />
      </React.StrictMode>
    </AuthContextProvider>
  </CookiesProvider>
);
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";

const OrdersPagination = ({...props}) => {
  const { page, setPage, countOrders, setLoading } = props;

  const handlePaginationClick = (e, type = 'next') => {
    let newPage = page;
    e.preventDefault();
    switch(type) {
      case 'first':
        newPage = 1;
        break;
      case 'last':
        newPage = Math.ceil(countOrders / 20);
        break;
      case 'prev':
        newPage--;
        break;
      default:
        newPage++
    }
    setPage(newPage);
    // setLoading(true);
  }
  return (
    <>
    { countOrders > 0
      ? (
      <ButtonToolbar className="mb-3 justify-content-between align-items-center" aria-label="Toolbar with Button groups">
        { countOrders > 0 ? (
          <span className="me-2">Show <strong>{(page-1)*20 + 1} - {Math.min(page * 20, countOrders)}</strong> of <strong>{countOrders}</strong> orders</span>
        ): (
          <span className="me-2">Not found</span>
        )}
        
        <ButtonGroup className="me-2" aria-label="Pagination buttons">
          <Button size="sm" disabled={ page === 1 ? true : false } variant="secondary" onClick={(e) => handlePaginationClick(e, 'first')}><FontAwesomeIcon icon={faAnglesLeft} /></Button>
          <Button size="sm" disabled={ page === 1 ? true : false } variant="secondary" onClick={(e) => handlePaginationClick(e, 'prev')}><FontAwesomeIcon icon={faAngleLeft} /></Button>
          <Button size="sm" disabled={ (page*20) >= countOrders ? true : false } variant="secondary" onClick={(e) => handlePaginationClick(e, 'next')}><FontAwesomeIcon icon={faAngleRight} /></Button>
          <Button size="sm" disabled={ (page*20) >= countOrders ? true : false } variant="secondary" onClick={(e) => handlePaginationClick(e, 'last')}><FontAwesomeIcon icon={faAnglesRight} /></Button>
        </ButtonGroup>
      </ButtonToolbar>
      ) : (
        <></>
      ) 
    }
    </>
    
  );
}

export default OrdersPagination;
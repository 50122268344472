import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Utils from "../../configs/Utils";
import Enums from "../../configs/Enums";
import { Badge, Col, Image, Placeholder, Row } from "react-bootstrap";

interface SellerProps {
  _id: string;
  count: number;
  countOrders: number;
  userDetails: {
    email: string;
    fullName: string;
  }
}

const SellerBlock: React.FC = () => {
  const [cookie] = useCookies(['token']);
  const [sellerAGGR, setSellerAGGR] = useState<SellerProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchSeller = async () => {
      try {
        const response: SellerProps[] = await Utils._apiRequest({
          path: Enums.endpoints.stats.STORES as string,
          type: 'GET',
          cookie
        });
        setSellerAGGR(response);
        setLoading(false);
      } catch(error) {
        console.log((error as Error).message);
      }
    }

    if (cookie.token) {
      fetchSeller();
    }
  }, [cookie]);

  return (
    <div className="my-3 p-3 bg-body rounded shadow-sm feature-box">
      <h6 className="heading border-bottom pb-2 mb-3">Sellser</h6>
      
      <Row>
      { loading ? [1,2,3,4,5,6].map(i => {
          return (
            <Col md={4}>
              <a href="#" className="list-group-item list-group-item-action d-flex shadow-sm gap-3 p-3 mb-3 rounded" style={{background: "#f0f0f0"}}>
                <div
                  style={{
                    width: '24px',
                    height: '22px',
                    backgroundColor: '#666',
                    borderRadius: '50em',
                  }}
                  className="mb-3"
                ></div>
                <div className="d-flex gap-2 w-100 justify-content-between">
                  <div style={{ width: '250px'}}>
                    <Placeholder as="h6" animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder as="p" animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  </div>
                  <strong className="text-body-secondary" style={{width: '50px'}}>
                    <Placeholder as="strong" animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  </strong>
                </div>
              </a>
            </Col>
          )
        }) 
        : sellerAGGR.map(seller => {
          return (
            <Col md={4}>
              <a href="#" className="list-group-item list-group-item-action d-flex shadow-sm gap-3 p-3 mb-3 rounded" style={{background: "#f6f6f6"}}>
                <Image src={Utils.getPublicImage('/assets/images/profile-icon-new.png')} roundedCircle width={22} height={22} />
                <div className="d-flex gap-2 w-100 justify-content-between">
                  <div>
                    <h6 className="mb-0 text-capitalize">{ seller.userDetails.fullName }</h6>
                    <p className="mb-0 opacity-75">{ seller.userDetails.email }</p>
                  </div>
                  <span className="text-end">
                    <Badge bg="warning-subtle" className="shadow-sm mb-1" text="dark">{seller.count} shops</Badge><br />
                    <Badge bg="primary-subtle" className="shadow-sm" text="dark">{seller.countOrders} solds</Badge>
                  </span>
                </div>
              </a>
            </Col>
          )
        })
      }
      </Row>
    </div>
  );
}

export default SellerBlock;
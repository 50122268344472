import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import StoreList from "./StoreList";
import Pagination from "../Common/Pagination";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation } from "react-router-dom";

const StoreBlock: React.FC = () => {
  const [count, setCount] = useState(0);
  const [limit] = useState(20);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get('page') || '1');
  const [page, setPage] = useState(currentPage);

  return (
    <>
    <Row>
      <Col md={12}>
        <div className="my-3 p-3 bg-body rounded shadow-sm feature-box">
          <h6 className="heading border-bottom pb-2 mb-0">Store</h6>
          <StoreList 
            page={page}
            setCount={setCount}
            limit={limit}
            // loading={loading}
            // setLoading={setLoading}
          />
          <Pagination 
            page={page}
            setPage={setPage}
            count={count}
            limit={limit}
            setLoading={setLoading}
          />
        </div>
      </Col>
    </Row>
    </>
  );
};

export default StoreBlock;
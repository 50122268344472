import React, { ReactNode } from "react";
import { useCookies } from "react-cookie";
import { useAuth } from "../contexts/AuthContext";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }): React.ReactElement | null => {
  const [cookie] = useCookies(['token']);
  const { currentUser } = useAuth();

  /**
   * Go to login page if:
   * + Token not exist
   * + Token exit, but couldn't fetch user data
  */
  if (!cookie.token || (currentUser && !currentUser._id)) {
    return <Navigate to="/login" />
  }

  /**
   * Redirect All protected page to account activate page if:
   * + Logged in, user status not activated
  */

  if (cookie.token && currentUser && currentUser.status !== 'activated') {
    return <Navigate to="/account/activate" />
  }

  // Continue the protect page if isAuthenticated
  return <>{ children }</>;
}

export default ProtectedRoute;
import React, { useEffect, useState } from "react";
import { Button, Form } from 'react-bootstrap';
import { useCookies } from "react-cookie";
import Utils from "../../configs/Utils";
import Loading from "../Common/Loading";
import Enums from "../../configs/Enums";

const StripeAccountForm = ({...props}) => {
  const [cookie] = useCookies(['']);
  const {setAccountList, updatingAccount, setUpdatingAccount} = props;

  const [accountName, setAccountName] = useState('');
  const [accountNameErr, setAccountNameErr] = useState('');

  const [publicKey, setPublicKey] = useState('');
  const [publicKeyErr, setPublicKeyErr] = useState('');

  const [secretKey, setSecretKey] = useState('');
  const [secretKeyErr, setSecretKeyErr] = useState('');

  const [publicKeyDev, setPublicKeyDev] = useState('');
  const [secretKeyDev, setSecretKeyDev] = useState('');

  const [sanbox, setSanbox] = useState(false);

  const [updateAccId, setUpdateAccId] = useState('');
  const [submitLable, setSubmitLable] = useState('Connect Stripe');

  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    if (updatingAccount._id) {
      setUpdateAccId(updatingAccount._id);
      console.log(updatingAccount);
      setSubmitLable('Update Account');
      setAccountName(updatingAccount.accountName);
      setPublicKey(updatingAccount.apis.live.publicKey || '');
      setSecretKey(updatingAccount.apis.live.secretKey || '');
      setSanbox(updatingAccount.apis.test.publicKey !== "");
      setPublicKeyDev(updatingAccount.apis.test.publicKey || '');
      setSecretKeyDev(updatingAccount.apis.test.secretKey || '');
    }
  }, [updatingAccount]);

  const resetForm = () => {
    setAccountName('');
    setPublicKey('');
    setSecretKey('');
    setPublicKeyDev('');
    setSecretKeyDev('');
    setUpdateAccId('');
    setSubmitLable('Connect Stripe');
  }
  
  const submitHandle = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    try {
      let requestParams = {};
      if (updateAccId.length) {
        // *UPDATE ACTION
        let payload = {};
        // add account to update data if it changed
        payload = accountName !== updatingAccount.accountName ? {...payload, accountName}: payload;     
        // Update domain if it changed
        payload = publicKey !== updatingAccount.apis.live.publicKey ? {...payload, publicKey}: payload;
        // Update domain if it changed
        payload = secretKey !== updatingAccount.apis.live.secretKey ? {...payload, secretKey}: payload;
        // Update domain if it changed
        payload = publicKeyDev !== updatingAccount.apis.test.publicKey ? {...payload, publicKeyDev}: payload;
        // Update domain if it changed
        payload = secretKeyDev !== updatingAccount.apis.test.secretKey ? {...payload, secretKeyDev}: payload;

        requestParams.path = Enums.endpoints.stripe.ACCOUNT_PR;
        requestParams.type = 'PUT';
        requestParams.pathParams = { id: updateAccId };
        requestParams.payload = payload;
      } else {
        // Create action
        requestParams.payload = {accountName, publicKey, secretKey, publicKeyDev, secretKeyDev};
        requestParams.path = Enums.endpoints.stripe.ACCOUNT;
        requestParams.type = 'POST';
      }

      const data = await Utils._apiRequest({
        ...requestParams, 
        cookie
      });
      if (data.errors) {
        if (data.errors.accountName && data.errors.accountName !== '') {
          setAccountNameErr(data.errors.accountName);
        }
        if (data.errors['live.publicKey'] && data.errors['live.publicKey'] !== '') {
          setPublicKeyErr(data.errors['live.publicKey']);
        }
        if (data.errors['live.secretKey'] && data.errors['live.secretKey'] !== '') {
          setSecretKeyErr(data.errors['live.secretKey']);
        }
      } else {
        setAccountList(data);
        resetForm();
        setUpdatingAccount({});
      }
    } catch(err) {
    }

    setFormLoading(false);
  };

  const handleCancelUpdating = (e) => {
    e.preventDefault();
    resetForm();
    setUpdatingAccount({});
  }

  return (
    <Form onSubmit={submitHandle} className={`my-3 p-3 p-md-4 border rounded-3 bg-body-tertiary${ formLoading ? ' loading' : '' }`}>
      <Loading show={formLoading} />

      <Form.Group className="mb-3" controlId="stripeAccountNameField">
        <Form.Label>Account Name</Form.Label>
        <Form.Control type="text" size="sm" placeholder="Business Name, Anithing remind you..." value={accountName} className={accountNameErr !== "" ? " is-invalid" : ""} onChange={(e) => setAccountName(e.target.value)} />
        { accountNameErr !== "" ? <p className="invalid-feedback">{ accountNameErr }</p> : null }
      </Form.Group>

      <Form.Group className="mb-3" controlId="publicKeyField">
        <Form.Label>Public Key (*)</Form.Label>
        <Form.Control type="text" size="sm" placeholder="pk_live............" value={publicKey} className={publicKeyErr !== "" ? " is-invalid" : ""} onChange={(e) => setPublicKey(e.target.value)} />
        { publicKeyErr !== "" ? <p className="invalid-feedback">{ publicKeyErr }</p> : null }
      </Form.Group>
      <Form.Group className="mb-3" controlId="secretKeyField">
        <Form.Label>Secret Key (*)</Form.Label>
        <Form.Control type="password" size="sm" placeholder="sk_live............" value={secretKey} className={secretKeyErr !== "" ? " is-invalid" : ""} onChange={(e) => setSecretKey(e.target.value)} />
        { secretKeyErr !== "" ? <p className="invalid-feedback">{ secretKeyErr }</p> : null }
      </Form.Group>

      <hr className="mt-4" />

      <Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        label="Save test keys?"
        className="mb-3"
        onChange={(e) => setSanbox(e.target.checked)}
        checked={sanbox ? true : false}
      />
      { sanbox ? (
        <>
        <Form.Group className="mb-3" controlId="publicKeyDevField">
          <Form.Label>Public Key (Dev)</Form.Label>
          <Form.Control type="text" size="sm" placeholder="pk_test............" value={publicKeyDev} onChange={(e) => setPublicKeyDev(e.target.value)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="secretKeyDevField">
          <Form.Label>Secret Key (Dev)</Form.Label>
          <Form.Control type="password" size="sm" placeholder="sk_test............" value={secretKeyDev} onChange={(e) => setSecretKeyDev(e.target.value)} />
        </Form.Group>
        </>
      ) : (<></>) }

      <Button type="submit" size="sm" className="me-2" variant="primary">{submitLable}</Button>
      { updateAccId.length ? (
        <Button size="sm" onClick={handleCancelUpdating} variant="dark">Cancel</Button>
      ) : (
        <></>
      ) }
    </Form>
  );
}

export default StripeAccountForm;
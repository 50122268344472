import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faShop } from "@fortawesome/free-solid-svg-icons";
import StoreBlock from "../../Components/Stores";
import Utils from "../../configs/Utils";
import { Button, Tab, Tabs } from "react-bootstrap";
import PopupModal from "../../Components/Common/Popup";
import { useAuth } from "../../contexts/AuthContext";
import CopyButton from "../../Components/Common/CopyButton";
import WoocommerceStoreForm from "../../Components/Stores/Woocommerce/wooStoreForm";

const StoresPage: React.FC = () => {
  const [popupShow, setPopupShow] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    Utils.setPageTitle('Stores page');
  }, []);

  return (
    <main className="page-main">
      <div className="main-container container">
        <div className="d-flex align-items-center p-3 my-3 text-white bg-primary rounded shadow-sm heading-box">
          <div className="d-flex align-items-center w-100">
            <FontAwesomeIcon className="me-3" fixedWidth size="xl" icon={faShop} />
            <div className="lh-1">
              <h2 className="h6 mb-0 text-white lh-1">Store List</h2>
              <small>Woocommerce, Tiktok shop, Shopify</small>
            </div>
          </div>
          
          <div className="d">
            <Button variant="dark" size="sm" onClick={() => { setPopupShow(true) }}>
              <FontAwesomeIcon className="me-2" icon={faPlus} />Add New Store
            </Button>
          </div>
        </div>
        
        <StoreBlock />
        <PopupModal title="Add store" popupShow={popupShow} setPopupShow={setPopupShow}>
          <Tabs defaultActiveKey="tiktok-shop" className="mb-3" id="add-new-store-tabs">
            <Tab eventKey="tiktok-shop" title="Tiktok shop">
              <h5>Copy authorization link</h5>
              <CopyButton 
                textToCopy={ `https://services.tiktokshops.us/open/authorize?service_id=7445647539631753003&state=${currentUser?._id}` }
              />
            </Tab>
            <Tab eventKey="woocommerce" title="Woocommerce">
              <WoocommerceStoreForm />
            </Tab>
          </Tabs>
        </PopupModal>
      </div>
    </main>
  );
}

export default StoresPage;
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { Form, Image, Spinner } from "react-bootstrap";
import Utils from "../../configs/Utils";
import { DesignProps } from "../../Models/Design";

type designField = 'mockup' | 'design';

interface DesignUploaderProps {
  field: designField;
  designUpdate: DesignProps;
  setDesignUpdate: React.Dispatch<React.SetStateAction<DesignProps | null>>;
}

const DesignUploader: React.FC<DesignUploaderProps> = ({ field, designUpdate, setDesignUpdate }) => {
  // Defined states
  const [cookie] = useCookies(['token']);
  const [preview, setPreview] = useState<string>(Utils.getPublicImage('/assets/images/img-placeholder-x150.png'));
  const [spinner, setSpinner] = useState<boolean>(false);

  useEffect(() => {
    if (designUpdate[field]?.url) {
      setPreview(designUpdate[field].url)
    }
  }, [designUpdate]);

  const handleUpload = async (file: File, field: designField) => {
    setSpinner(true);
    const formData = new FormData();
    const fileName = encodeURIComponent(`${designUpdate.workspace._id || designUpdate.workspace}/${designUpdate.name}/${file.name}`);
    formData.append("image", file, fileName);
    formData.append("id", designUpdate._id);
    formData.append("field", field);
    const design = await Utils._apiRequest({
      path: "design.upload",
      type: 'POST',
      dataType: 'multipart/form-data',
      payload: formData,
      cookie
    });
    setDesignUpdate(design);
    setSpinner(false);
  }

  return (
    <div className="d-flex align-items-center">
      <div className={`upload-preview me-2${ spinner ? ' img-spinner' : '' }`}>
        { spinner ? ( <Spinner animation="grow" /> ) : (<></>) }
        <Image src={preview} width={40} height={40} rounded className="m-0 img-cover" />
      </div>
      <Form.Control disabled={ spinner } size="sm" type="file" onChange={(e: React.ChangeEvent<HTMLInputElement>) => e.target.files && handleUpload(e.target.files[0] , field) } />
    </div>
  );
}

export default DesignUploader;
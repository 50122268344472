import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../pages/Layout";
import ProtectedRoute from "./ProtectedRoute";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
import PagePolicy from "../pages/Policies";
import AuthStore from "../pages/AuthStore";
import AuthDrive from "../pages/AuthPages/AuthDrive";
import AccountActivatePage from "../pages/Account/activate";
import Gateways from "../pages/Gateways";
import StoresPage from "../pages/Stores";
import StoreDetailPage from "../pages/Stores/storeDetail";
import OrdersPage from "../pages/Orders";
import DesignsPage from "../pages/Designs";
import SettingsPage from "../pages/Settings";
import NotFound from "../pages/NotFound";

// Import css
import "../assets/css/general.scss";
import Dashboard from "../pages/Dashboard";

const AppRouter: React.FC = () => {
  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/policy" element={<PagePolicy />} />
          <Route path="/authStore" element={<AuthStore />} />
          <Route path="/auth/drive" element={<AuthDrive />} />
          <Route path="/account/activate/:id?" element={<AccountActivatePage />} />
          <Route path="/stores" element={
            <ProtectedRoute>
              <StoresPage />
            </ProtectedRoute>
          } />
          <Route path="/stores/:id" element={
            <ProtectedRoute>
              <StoreDetailPage />
            </ProtectedRoute>
          } />
          <Route path="/orders" element={
            <ProtectedRoute>
              <OrdersPage />
            </ProtectedRoute>
          } />
          <Route path="/designs" element={
            <ProtectedRoute>
              <DesignsPage />
            </ProtectedRoute>
          } />
          <Route path="/settings" element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          } />
          <Route path="/gateways" element={
            <ProtectedRoute>
              <Gateways />
            </ProtectedRoute>
          } />
          <Route path='*' element={<NotFound />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { Button, Image, Table } from "react-bootstrap";
import { faGoogleDrive } from "@fortawesome/free-brands-svg-icons";
import { faLinkSlash, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAuth } from "../../contexts/AuthContext";
import Utils from "../../configs/Utils";
import Enums from "../../configs/Enums";

import GooglePicker from "../GooglePicker";
import { Link } from "react-router-dom";
import { Option } from "../../Models/Option";

interface SheetItem {
  url: string;
  iconUrl: string;
  name: string;
  lastEditedUtc: number;
}

interface Setting extends Option {
  values: SheetItem;
}

interface SheetsSettingsTabProps {
  oath2Token: {
    access_token?: string;
    [key: string]: any;
  };
  setOath2Token: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  settings: Option[];
}

const SheetsSettingsTab: React.FC<SheetsSettingsTabProps> = ({ 
  oath2Token, 
  setOath2Token, 
  settings 
}) => {
  const { currentUser } = useAuth();
  const [cookie] = useCookies(['token']);
  const [sheetsList, setSheetsList] = useState<Setting[]>([]);
  const [deleting, setDeleting] = useState<boolean>(false);

  useEffect(() => {
    const filterdSettings = settings.filter((item: Setting) => {
      return item.group === "tiktok_lising_sheets";
    });
    setSheetsList(filterdSettings);
  }, [settings]);

  const getGoogleAuthUrl = async () => {
    const data = await Utils._apiRequest({
      path: Enums.endpoints.google.oauth2 as string,
      type: 'GET',
      cookie
    });
    if (!data.message) {
      window.location.replace(data.authUrl);
    }
  }

  const deleteItemHandle = async (id: string) => {
    const confirm = window.confirm(`Are you sure you want to delete it? This action cannot be undone.`);
    if (confirm) {
      setDeleting(true);
      const data = await Utils._apiRequest({
        path: Enums.endpoints.options.DELETE as string, 
        pathParams: {id},
        type: 'DELETE', 
        cookie
      });
      if (data._id) {
        const refreshData = sheetsList.filter((item) => {
          return item._id !== data._id;
        });
        setSheetsList(refreshData);
      } else {
        alert('Something was wrong!');
      }
      setDeleting(false);
    }
  }

  return(
    <>
    <h6 className="border-bottom pb-2 mb-3 d-block">Connect Your Google Account</h6>
    <div className="col-lg-12">
      <Button variant={ currentUser?.meta?.oath2Token ? 'success' : 'secondary' } className='d-flex justify-content-center align-items-center' style={{ paddingLeft: "1.6em", paddingRight: "1.6em" }} onClick={getGoogleAuthUrl} size='lg'>
        { currentUser?.meta?.oath2Token ? (
          <>
          <Image width={20} className='me-2' src={currentUser?.meta.oath2Token.user.picture} roundedCircle />
          {currentUser?.meta.oath2Token.user.name}
          <small className='ms-2'>( <FontAwesomeIcon icon={faLinkSlash} size='sm' /> Reconnect )</small>
          </>
        ) : (
          <>
          <FontAwesomeIcon icon={faGoogleDrive} className='me-2'/>Connect Google Drive
          </>
        ) }
      </Button>
    </div>
    
    { oath2Token.access_token ? (
      <>
      <h6 className="border-bottom pb-2 mb-0 mt-5 d-block">Tiktok listing sheets</h6>
      <Table striped hover className="text-nowrap">
        <thead>
          <tr>
            <th style={{ width: 30 }}>#</th>
            <th>File name</th>
            <th>Last modified</th>
            <th style={{ width: 50 }}>...</th>
          </tr>
        </thead>
        <tbody>
          { sheetsList.length ? (
            sheetsList.map((sheet, i) => {
              return (
                <tr key={i} className={`table-row${deleting ? ' deleting': ''}`}>
                  <td>{i+1}</td>
                  <td>
                    <Link to={ sheet.values.url } className="text-dark" target="_blank">
                    <Image width={20} className="me-2" src={ sheet.values.iconUrl } />
                    { sheet.values?.name }
                    </Link>
                  </td>
                  <td>
                    { Utils.dateFormat(sheet.values.lastEditedUtc, false) }
                  </td>
                  <td>
                    <Button className="ms-1" size="sm" variant="danger" onClick={() => { deleteItemHandle(sheet._id) }} title="Delete item"><FontAwesomeIcon icon={faTrashCan} /></Button>
                  </td>
                </tr>
              )
            }) 
          ) : (
            <tr>
              <td colSpan={3}><i>Nothing to show...</i></td>
            </tr>
          ) }
        </tbody>
      </Table>
      <div className="col-lg-12 d-flex justify-content-end">
        <GooglePicker oauth2Token={currentUser?.meta.oath2Token} setOath2Token={setOath2Token} sheetsList={sheetsList} setSheetsList={setSheetsList} />
      </div>
      </>
    ) : (
      <></>
    ) }
    </>
  );
}

export default SheetsSettingsTab;
import React from "react";
import { Outlet } from "react-router-dom";
import PageFooter from "../../Components/Footer";
import PageHeader from "../../Components/Header";

const Layout: React.FC = () => {
  return (
    <div className='main-app-wrapper'>
      <PageHeader />
      <Outlet />
      <PageFooter />
    </div>
  );
}

export default Layout;
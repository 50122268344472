import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import OrderedList from "./orderedList";
import OrderFilterForm from "./ordersFilterForm";
import OrdersPagination from "./ordersPagination";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(1);
  const [countOrders, setCountOrders] = useState(0);
  const [loading, setLoading] = useState(true);
  
  return(
    <Row className="mb-5">
      <Col md={12}>
        <div className="my-3 p-3 bg-body rounded shadow-sm feature-box">
          <h6 className="heading pb-2 mb-0">Order list</h6>
          
          <div className="d-none d-md-flex align-items-center justify-content-between highlight-form-box border p-2">
            <Col>
              <OrderFilterForm setFilter={setFilter} />
            </Col>
            <Col md="auto">
              <OrdersPagination 
                page={page} 
                setPage={setPage} 
                countOrders={countOrders} 
                setLoading={setLoading}
              />
            </Col>
          </div>
          
          <OrderedList 
            orders={orders} 
            setOrders={setOrders} 
            countOrders={countOrders} 
            setCountOrders={setCountOrders} 
            page={page} 
            setPage={setPage}
            loading={loading} 
            setLoading={setLoading}
            filter={filter}
          />
          <OrdersPagination 
            page={page} 
            setPage={setPage} 
            countOrders={countOrders} 
            setLoading={setLoading}
          />
        </div>
      </Col>
    </Row>
  );
}

export default Orders;
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Navbar, Placeholder, Row, Spinner, Table } from "react-bootstrap";
import Utils from "../../configs/Utils";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowsRotate, faShop } from "@fortawesome/free-solid-svg-icons";
import CopyButton from "../../Components/Common/CopyButton";
import QuickEdit from "../../Components/Common/QuickEdit";
import Enums from "../../configs/Enums";

import "./storeDetail.scss";
import { Store } from "../../Models/Store";

const StoreDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [cookie] = useCookies([]);
  const [storeDetail, setStoreDetail] = useState<Store | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    Utils.setPageTitle('Store detail page');
  }, []);

  useEffect(() => {
    const fetchStoreDetail = async () => {
      try {
        const data = await Utils._apiRequest({
          path: Enums.endpoints.store.DETAIL as string,
          pathParams: {
            id: id as string
          },
          type: 'GET',
          cookie
        });
        setStoreDetail(data);
      } catch (error) {
        console.log((error as Error).message);
      }
    }
    
    fetchStoreDetail();
  }, [cookie, id]);

  const onQuickEditUpdate = (key: string, value: string) => {
    const updatedStoreDetail = { ...storeDetail, [key]: value } as Store;
    setStoreDetail(updatedStoreDetail);
  }

  const onRefreshTokenHandle = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await Utils._apiRequest({
      path: Enums.endpoints.tiktok.AUTH_TOKEN as string,
      type: 'GET',
      pathParams: {
        id: id as string
      },
      cookie
    });
    setStoreDetail(response);
    setIsLoading(false);
  }

  return (
    <main className="page-main page-store-detail">
      <div className="main-container container">
        <Navbar className="justify-content-between align-items-center text-white bg-primary p-3 my-3 rounded shadow-sm heading-box">
          <div className="d-flex align-items-center w-100">
            <FontAwesomeIcon className="me-3" fixedWidth size="xl" icon={faShop} />

            <div className="lh-2 flex-grow-1">
              <h2 className="h6 mb-0 text-white lh-1">
                { storeDetail?._id ? (storeDetail.storeFriendlyName || storeDetail.storeName) : (
                  <Placeholder animation="glow">
                    <Placeholder xs={4} />
                  </Placeholder>
                ) }
              </h2>
              <small>
                { storeDetail?._id ? storeDetail.domain : (
                  <Placeholder animation="glow">
                    <Placeholder size="sm" xs={3} />
                  </Placeholder>
                ) }
              </small>
            </div>
          </div>
          <div className="d">
            <Button variant="dark" className={storeDetail?._id ? '' : 'btn-placeholder'} size="sm" onClick={() => navigate('/stores')}>
              <FontAwesomeIcon className="me-2" icon={faArrowLeftLong} />Back to Stores
            </Button>
          </div>
        </Navbar>

        <Row>
          <Col md={12}>
            <div className="my-3 p-3 bg-body rounded shadow-sm feature-box">
              <h6 className="heading border-bottom pb-2 mb-0">Details</h6>
              <Table className="store-detail-table w-100 mx-auto border-bottom" style={{ tableLayout: 'fixed' }} striped size="sm" borderless>
                <tbody>
                  <tr>
                    <th className="text-end pe-4">Store Name</th>
                    <td>
                      { storeDetail?.storeName ? storeDetail.storeName
                        : (
                          <Placeholder animation="glow">
                            <Placeholder xs={3} />
                          </Placeholder>
                        )
                      }
                    </td>
                  </tr>
                  { storeDetail?.storeFriendlyName && 
                    <tr>
                      <th className="text-end pe-4">Store Friendly Name</th>
                      <td>
                        <QuickEdit onUpdate={onQuickEditUpdate} path={Enums.endpoints.store.DETAIL as string} pathParams={ {id} } param="storeFriendlyName" defaultValue={ storeDetail.storeFriendlyName } />
                      </td>
                    </tr>
                  }

                  { (storeDetail?.flatform === 'woocommerce') && 
                    <tr>
                      <th className="text-end pe-4">Store Time-Zone</th>
                      <td>
                        <QuickEdit onUpdate={onQuickEditUpdate} path={Enums.endpoints.store.DETAIL as string} pathParams={ {id} } param="meta.time_zone" defaultValue={ storeDetail?.meta?.time_zone || '+7' } />
                      </td>
                    </tr>
                  }

                  { storeDetail?.domain && 
                    <tr>
                      <th className="text-end pe-4">Domain</th>
                      <td>{ storeDetail.domain }</td>
                    </tr>
                  }
                  
                  <tr>
                    <th className="text-end pe-4">Flatform</th>
                    <td>
                      { storeDetail?.flatform ? storeDetail.flatform
                        : (
                          <Placeholder animation="glow">
                            <Placeholder xs={2} />
                          </Placeholder>
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <th className="text-end pe-4">Status</th>
                    <td>
                      { storeDetail?.status ? Utils.buildBadgeLable(storeDetail.status)
                        : (
                          <Placeholder animation="glow">
                            <Placeholder xs={2} />
                          </Placeholder>
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <th className="text-end pe-4">Store Prefix</th>
                    <td>
                      { storeDetail?.storePrefix ? storeDetail.storePrefix
                        : (
                          <Placeholder animation="glow">
                            <Placeholder xs={8} />
                          </Placeholder>
                        )
                      }
                    </td>
                  </tr>

                  { storeDetail?.storeId && 
                    <tr>
                      <th className="text-end pe-4">Store ID</th>
                      <td>{ storeDetail.storeId }</td>
                    </tr>
                  }

                  { storeDetail?.meta?.cipher && 
                    <tr>
                      <th className="text-end pe-4">Shop cipher</th>
                      <td><CopyButton textToCopy={ storeDetail.meta.cipher } /></td>
                    </tr>
                  }
                  { storeDetail?.meta?.code && 
                    <tr>
                      <th className="text-end pe-4">Shop code</th>
                      <td><CopyButton textToCopy={ storeDetail.meta.code } /></td>
                    </tr>
                  }
                  { storeDetail?.meta?.region && 
                    <tr>
                      <th className="text-end pe-4">Shop region</th>
                      <td>{ storeDetail.meta.region }</td>
                    </tr>
                  }
                  { storeDetail?.consumer?.access_token && 
                    <tr>
                      <th className="text-end pe-4">Access token</th>
                      <td><CopyButton textToCopy={ storeDetail.consumer.access_token } /></td>
                    </tr>
                  }
                  { storeDetail?.consumer?.access_token_expire_in && 
                    <tr>
                      <th className="text-end pe-4">Access token expired</th>
                      <td>
                        <CopyButton 
                          textToCopy={ storeDetail.consumer.access_token_expire_in } 
                          textToDisplay={ Utils.dateFormat(storeDetail.consumer.access_token_expire_in, false) } 
                        />
                      </td>
                    </tr>
                  }
                  { storeDetail?.consumer?.refresh_token && 
                    <tr>
                      <th className="text-end pe-4">Refresh token</th>
                      <td><CopyButton textToCopy={ storeDetail.consumer.refresh_token } /></td>
                    </tr>
                  }
                  { storeDetail?.consumer?.refresh_token_expire_in && 
                    <tr>
                      <th className="text-end pe-4">Refresh token expired</th>
                      <td>
                        <CopyButton 
                          textToCopy={ storeDetail.consumer.refresh_token_expire_in } 
                          textToDisplay={ Utils.dateFormat(storeDetail.consumer?.refresh_token_expire_in, false) }
                        />
                      </td>
                    </tr>
                  }
                </tbody>
              </Table>
              <div className={ storeDetail?.flatform === 'tiktokshop' ? 'd-flex justify-content-end' : 'd-none' }>
                <Button disabled={isLoading} variant="warning" onClick={onRefreshTokenHandle} >
                  { isLoading ? ( 
                    <>
                    <Spinner animation="grow" size="sm" className="me-2" />
                    <i>Updating new token</i>
                    </> 
                  ) : (
                    <>
                    <FontAwesomeIcon icon={faArrowsRotate} size="sm" className="me-2" />
                    Refresh token
                    </>
                  ) }
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </main>
  );
}

export default StoreDetailPage;
import React, { useEffect, useState } from "react";
import Utils from "../../configs/Utils";
import { Link } from "react-router-dom";
import { Badge, Button, Image } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";

import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faTag } from "@fortawesome/free-solid-svg-icons";

import "./orderListItem.scss";
import Enums from "../../configs/Enums";
import { useCookies } from "react-cookie";

const OrderedListItem = ({...props}) => {
  const { id, index, order, store, seller, setPopupShow, setPopupData } = props;
  const [qty, setQty] = useState(0);
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [cookie] = useCookies([]);
  const [labelVisible, setLabelVisible] = useState(false);

  const orderRowClickHandle = (e) => {
    e.preventDefault();
    setPopupData(order);
    setPopupShow(true);
  }

  const recipient = order.billing || order.recipient_address;
  const backgroundColorSeller = Utils.stringToColor(seller.fullName);
  const itemQty = order.line_items.length;

  useEffect(() => {
    if (order.status === 'AWAITING_SHIPMENT') {
      setLabelVisible(true);
    }
  }, [order]);

  const createShippingLabel = async (e, orderId) => {
    e.preventDefault();
    setIsLoading(true);
    const data = await Utils._apiRequest({
      path: Enums.endpoints.tiktok.CREATE_LABLE, 
      payload: {
        orderId: orderId
      },
      type: 'POST', 
      cookie
    });

    setIsLoading(false);
    if (data.message) {
      alert(data.message);
      return;
    }

    console.log('Create label success, please reload this page');
    setLabelVisible(false);
  }

  return (
    <tr className={ `table-row orderListItem-row${isLoading ? " loading": ""}`} data-id={id}>
      <td>{ index }</td>
      <td>
        <div className="orderListItem-thumb" style={{
          backgroundImage: `url("${order.line_items[0].sku_image}")`,
        }}></div>
      </td>
      <td><Link to="#" onClick={(e) => orderRowClickHandle(e)}>{ order.number || order.id }</Link></td>
      <td>{ Utils.buildBadgeLable(order.status) }</td>
      <td>{ recipient.first_name } { recipient.last_name }</td>
      <td>{ itemQty }</td>
      <td>
        { order.total || order.payment.total_amount } { order.currency || order.payment.currency }
      </td>
      <td><span className="text-overflow" title={ store.storeName }>{ store.storeName }</span></td>
      { currentUser?.admin ? <td><Badge bg="custom" style={{backgroundColor: backgroundColorSeller}} text="Light">{  seller?.fullName || 'N/A' }</Badge></td> : <></> }
      <td>{ Utils.dateFormat(order.create_time) }</td>
      <td className="position-relative">
        { labelVisible ? (
          <Tippy content="Create shipping label" theme="light" animation="scale">
          <Button size="sm" variant="warning" onClick={(e) => createShippingLabel(e, id) }>
            <FontAwesomeIcon icon={faTag} />
          </Button>
        </Tippy>
        ) : <></> }
      </td>
      
    </tr>
  );
}

export default OrderedListItem;
type ConfigType = {
  APIs: any;
  app: {
    name: string;
  };
  getApiURL: (path: string) => string;
};

const Config: ConfigType  = {
  APIs: {
    store: {
      all: '/v1/store/all',
      woo: '/v1/store/woo',
      detail: '/v1/store/[id]',
      tiktokAuth: '/v1/store/tiktok/auth',
      tiktokAuthShop: '/v1/store/tiktok/authShop'
    },
    orders: {
      fullSync: '/v1/orders/sync/stores',
      syncTasks: '/v1/orders/sync/tasks',
      all: '/v1/orders/page/[page]'
    },
    workspace: {
      all: '/v1/workspace'
    },
    design: {
      all: '/v1/design',
      del: '/v1/design/[id]',
      upload: '/v1/design/upload'
    }
  },
  app: {
    name: "Onealdern Apps"
  },
  getApiURL: (path: string): string => {
    const apiPath = `/${process.env.REACT_APP_API_VERSION}${path}`;
    return process.env.REACT_APP_API_URI + apiPath;
  }
}

export default Config;
import React, { ReactNode } from "react";
import { Modal } from "react-bootstrap";

interface PopupModalProps {
  title: string;
  popupShow: boolean;
  setPopupShow: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
}

const PopupModal: React.FC<PopupModalProps> = ({...props}) => {
  const { title, popupShow, setPopupShow, children } = props;

  return (
    <Modal
      show={popupShow}
      onHide={() => setPopupShow(false)}
      centered
      // backdrop="static"
      // keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

export default PopupModal;

import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';
import Utils from '../../configs/Utils';
import Enums from '../../configs/Enums';

const AuthDrive = () => {
  const [searchParams] = useSearchParams();
  const [cookie] = useCookies('');
  

  useEffect(() => {
    const fetchAuth = async () => {
      const code = searchParams.get('code');
      await Utils._apiRequest({
        path: Enums.endpoints.google.oauth2Callback,
        type: 'GET',
        query: { code },
        cookie
      });
      
      window.location.href = '/settings';
    }

    fetchAuth();
  }, []);

  return (
    <main className='main-page container mt-5'>
      <h2>Auth Drive page</h2>
      <p></p>
    </main>
  );
}

export default AuthDrive;
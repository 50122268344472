import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { Badge, Button } from "react-bootstrap";
import { faFilterCircleDollar, faTrashCan, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Utils from "../../configs/Utils";
import Enums from "../../configs/Enums";


const StripeAccountListItem = ({ ...props }) => {
  const [cookie] = useCookies(['']);
  const {
    index, 
    account, 
    accountList, 
    setAccountList, 
    updatingAccount,
    setUpdatingAccount
  } = props;
  const [deleting, setDeleting] = useState(false);

  const handleDeleteAccount = async (id) => {
    let conf = window.confirm("Are you sure you want to remove it?");
    if (conf) {
      setDeleting(true);
      try {
        const data = await Utils._apiRequest({
          path: Enums.endpoints.stripe.ACCOUNT_PR, 
          pathParams: {id},
          type: 'DELETE', 
          cookie
        });
        // remove current account from list
        let refreshData = accountList.filter((item) => {
          return item._id !== data._id;
        });
        setAccountList(refreshData);
      } catch(err) {
        setDeleting(false);
        window.alert(err.message);
      }
    }
  }

  const buildStatusLable = (status) => {
    switch(status) {
      case "pending":
        return <Badge bg="warning" text="dark">{status}</Badge>
      case "suspended":
        return <Badge bg="danger">{status}</Badge>
      case "unconnected":
        return <Badge bg="secondary">{status}</Badge>
      default:
        return <Badge bg="success">{status}</Badge>
    }
  }

  return (
    <>
    <tr className={
      `table-row${deleting ? ' deleting': ''}${updatingAccount && updatingAccount._id ? (updatingAccount._id === account._id ? ' updating' : ' disabled') : ''}`
    }>
      <td>{(index + 1)}</td>
      <td>{ account.accountName }</td>
      <td>{ account.account }</td>
      <td>{ buildStatusLable(account.status) }</td>
      <td className="text-overflow">{ account.domain }</td>
      <td>{account.meta.payout?.delay_days || 'N/A'} days ({ account.meta.payout?.interval || 'N/A' })</td>
      <td className="text-inline text-end">
        <Button className="ms-1" size="xs" variant="dark" onClick={(e) => setUpdatingAccount(account)} title="Edit account"><FontAwesomeIcon icon={faWandMagicSparkles} /></Button>
        <Button className="ms-1" size="xs" variant="primary" onClick={(e) => setUpdatingAccount(account)} title="Edit account"><FontAwesomeIcon icon={faFilterCircleDollar} /></Button>
        <Button className="ms-1" size="xs" variant="danger" onClick={(e) => handleDeleteAccount(account._id)} title="Delete account"><FontAwesomeIcon icon={faTrashCan} /></Button>
      </td>
    </tr>
    </>
  )
}

export default StripeAccountListItem;
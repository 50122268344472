type EndpointGroup = {
  [key: string]: string | EndpointGroup
}

type EnumsType = {
  endpoints: {
    auth: EndpointGroup;
    store: EndpointGroup;
    tiktok: EndpointGroup;
    google: EndpointGroup;
    options: EndpointGroup;
    stripe: EndpointGroup;
    stats: EndpointGroup;
  },
  Regex: {
    NAME: RegExp;
    EMAIL: RegExp;
    PASS: RegExp;
  }
}

const Enums: EnumsType = Object.freeze({
  endpoints: {
    auth: {
      LOGIN: "/auth/login",
      SIGNUP: "/auth/signup",
      LOGOUT: "/auth/logout",
      ME: "/auth/me",
      ACTIVATE: "/auth/activate/[token]"
    },
    store: {
      ALL: "/store/all",
      AGGR: "/store/aggregate",
      WOO: "/store/woo",
      DETAIL: "/store/[id]",
      WOO_AUTH: "/store/woo/auth",
      SEARCH: "/store/search"
    },
    orders: {
      ALL: "/orders"
    },
    stats: {
      STORES: '/stats/stores',
      STORES_ORDER_COUNT: "/stats/storesByOrderCount",
      ORDER_COUNT_BY_DAY: "/stats/ordersReport"
    },
    tiktok: {
      AUTH_TOKEN: "/tiktok/auth/token/[id]",
      CREATE_LABLE: "/tiktok/fulfillment",
      CREATE_FLASH_SALE: "/tiktok/promotion/createFlashDeal"
    },
    google: {
      oauth2: "/google/oauth2",
      oauth2Callback: "/google/oauth2/callback",
      oauth2Refresh: "/google/oauth2/refresh"
    },
    options: {
      SAVE: "/options",
      LIST: "/options",
      DELETE: "/options/[id]"
    },
    stripe: {
      ACCOUNT: "/gateway/stripe/account",
      ACCOUNT_PR: "/gateway/stripe/account/[id]"
    }
  },
  Regex: {
    NAME: /^[a-zA-Z][a-zA-Z0-9 ]{2,23}$/,
    EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    PASS: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{6,24}$/
  }
} as const);

export default Enums;
import React, { ReactNode } from "react";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button } from "react-bootstrap";

interface CopyButtonProps {
  textToCopy: string;
  textToDisplay?: string;
  children?: ReactNode;
}

const CopyButton: React.FC<CopyButtonProps> = ({ textToCopy, textToDisplay = textToCopy, children }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  }

  return (
    <div className="d-flex justify-content-between align-items-center me-1">
      <span className="text-truncate pe-3">{ children || textToDisplay }</span>
      <Button onClick={handleCopy} variant="secondary" size="sm">
        { isCopied ? (
          <><FontAwesomeIcon icon={faCheck} /> Copied!</>
        ) : (
          <><FontAwesomeIcon icon={faCopy} /> Copy</>
        ) 
        }
      </Button>
    </div>
  );
}

export default CopyButton;
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Utils from "../../configs/Utils";
import Enums from "../../configs/Enums";
import moment from "moment";
import { Area, AreaChart, Bar, CartesianGrid, ComposedChart, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface OrderCountByDaysProps {
  _id: String;
  count: Number;
};

const OrderCountByDays: React.FC = () => {
  const [cookie] = useCookies(['token']);
  const [data, setData] = useState<OrderCountByDaysProps[]>([]);

  useEffect(() => {
    const fillMissingDays = (data: OrderCountByDaysProps[]) => {
      const last7Days = Array.from({ length: 15 }, (_, i) =>
        moment().subtract(14 - i, 'days').format('YYYY-MM-DD')
      );    
    
      const filledData = last7Days.map((day) => {
        const existingDay = data.find((item) => item._id === day);
        return existingDay || { _id: day, count: 0 };
      });

      return filledData;
    };
    const fetchData = async () => {
      try {
        const response: OrderCountByDaysProps[] = await Utils._apiRequest({
          path: Enums.endpoints.stats.ORDER_COUNT_BY_DAY as string,
          type: 'GET',
          cookie
        });
        
        const completeData = fillMissingDays(response);
        setData(completeData);
      } catch(error) {
        console.log((error as Error).message);
      }
    }

    if (cookie.token) {
      fetchData();
    }
  }, [cookie]);

  return (
    <div className="my-3 p-3 bg-body rounded shadow feature-box">
      <h6 className="heading border-bottom pb-2 mb-3">Orders By Date</h6>
      
      <div style={{ width: '100%', height: '400px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart width={500} height={400} data={data} margin={{top: 20, left: -25}}>
            <CartesianGrid strokeDasharray="3 3" />
            <Bar dataKey="count" barSize={30} fill="#413ea0" label={{ position: 'top' }} />
            
            <XAxis dataKey="_id" />
            <YAxis />
            <Tooltip />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default OrderCountByDays;
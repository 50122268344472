import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { FloatingLabel, Form, Image } from 'react-bootstrap';

import { useAuth } from "../contexts/AuthContext";
import Utils from "../configs/Utils";
import Enums from "../configs/Enums";

import "../assets/css/account.scss";
import Loading from "../Components/Common/Loading";

const Login: React.FC = () => {
  const userRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies(['token']);
  const { setCurrentUser } = useAuth();
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailErrMsg, setEmailErrMsg] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordErrMsg, setPasswordErrMsg] = useState<string>('');

  useEffect(() => {
    Utils.setPageTitle('Login page');
  }, []);

  useEffect(() => {
    userRef.current?.focus();

    // Redirect to home page if logged in
    if (cookie && cookie.token && cookie.token !== 'null') {
      console.log('[check cookie] redirest to Home page');
      navigate('/');
      // window.location.href = '/';
    }
  }, [cookie, navigate]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let valid = true;
    if (!email.length) {
      setEmailErrMsg("This field is required!");
      valid = false;
    }
    if (!password.length) {
      setPasswordErrMsg("This field is required!");
      valid = false;
    }

    if (!valid) {
      return false;
    }

    setFormLoading(true);

    const data = await Utils._apiRequest({
      path: Enums.endpoints.auth.LOGIN as string,
      type: 'POST',
      payload: { email, password }
    });

    if (data.errors) {
      if (data.errors && data.errors.email !== "") {
        setEmailErrMsg(data.errors.email);
      }
      if (data.errors && data.errors.password !== "") {
        console.log(data.errors.password);
        setPasswordErrMsg(data.errors.password);
      }
      setFormLoading(false);
    } else {
      // Set cookie
      setCookie("token", "Bearer " + data.token, { path: "/", expires: new Date(data.tokenExp * 1000), secure: true });
      setCurrentUser(data);
      window.location.href = '/';
    }
  }

  const clearErrMessage = (type: 'email' | 'password') => {
    if(type === 'email') {
      setEmailErrMsg('')
    } else {
      setPasswordErrMsg('');
    }
  }

  return (
    <main className="page-main account-page container">
      <section className="login-wrapper">
        <Form className={`p-4 p-md-5 border overflow-hidden shadow rounded-3 bg-body-tertiary${ formLoading ? ' loading' : '' }`} onSubmit={handleSubmit}>
          <Loading show={formLoading} />
          <h1 className="logo text-center">
            <Image src={Utils.getPublicImage('/assets/images/logo-main.png')} width={145} alt="" />
          </h1>

          <FloatingLabel
            controlId="emailFloatingInput"
            label="Email address"
            className="mb-3"
          >
            <Form.Control
              ref={userRef}
              type="email"
              className={emailErrMsg !== "" ? " is-invalid" : ""}
              placeholder="name@example.com"
              onChange={(e) => { setEmail(e.target.value); clearErrMessage('email') }}
            />
            {emailErrMsg !== "" ? <p className="invalid-feedback">{emailErrMsg}</p> : null}
          </FloatingLabel>

          <FloatingLabel
            controlId="passwordFloatingInput"
            label="Password"
            className="mb-3"
          >
            <Form.Control
              type="password"
              className={passwordErrMsg !== "" ? " is-invalid" : ""}
              onChange={(e) => { setPassword(e.target.value); clearErrMessage('password') }}
              autoComplete="false"
              placeholder="password"
            />
            {passwordErrMsg !== "" ? <p className="invalid-feedback">{passwordErrMsg}</p> : null}
          </FloatingLabel>

          <button className="w-100 btn btn-lg btn-primary">Login</button>

          <div className="footer">
            <p className="mb-1">You don&lsquo;t have an account? <Link to="/register">Register</Link></p>
            <p>By continuing, you agree to our <Link to="/policy">Privacy Policy</Link>.</p>
          </div>
        </Form>
      </section>
    </main>
  );
}

export default Login;
import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Col, Row } from "react-bootstrap";
import SellerBlock from "../../Components/Stats/SellerBlock";
import StoreByOrderCount from "../../Components/Stats/StoreByOrderCount";
import OrderCountByDays from "../../Components/Stats/OrderCountByDays";

const Dashboard: React.FC = () => {
  const { currentUser } = useAuth();

  return (
    <main className='page-main'>
      <div className="main-container container">
        <h2 className="mb-3">Dashboard</h2>
        { currentUser?.admin ? (
          <Row>
            <Col md={12}>
              <SellerBlock />
            </Col>
          </Row>
        ) : (
          <></>
        ) }

        <Row>
          <Col md={4}>
            <StoreByOrderCount />
          </Col>
          <Col md={8}>
            <OrderCountByDays />
          </Col>
        </Row>
      </div>
    </main>
  );
}

export default Dashboard;
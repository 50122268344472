import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import Utils from "../../configs/Utils";
import DesignForm from "../../Components/Designs/designForm";
import { useState } from "react";
import DesignList from "../../Components/Designs/designList";
import { DesignProps } from "../../Models/Design";

const DesignsPage: React.FC = () => {
  const [designUpdate, setDesignUpdate] = useState<DesignProps | null>(null);
  const [fetching, setFetching] = useState<boolean>(false);

  return (
    <main className="page-main">
      <div className="main-container container">
        <div className="d-flex align-items-center p-3 my-3 text-white bg-primary rounded shadow-sm heading-box">
          <Image rounded src={Utils.getPublicImage('/assets/images/woo-section-icon-w.png')} width={38} className="me-3" alt="Stripe heading img" />
          <div className="lh-1">
            <h2 className="h6 mb-0 text-white lh-1">Designs</h2>
            <small>Sync orders, ...</small>
          </div>
        </div>

        <Row>
          <Col md={3}>
            <DesignForm designUpdate={designUpdate} setDesignUpdate={setDesignUpdate} setFetching={setFetching} />
          </Col>
          <Col md={9}>
            <div className="my-3 p-3 bg-body rounded shadow-sm feature-box">
              <h6 className="heading pb-2 mb-0 border-bottom">Order list</h6>
              <DesignList designUpdate={designUpdate} setDesignUpdate={setDesignUpdate} fetching={fetching} setFetching={setFetching} />
            </div>
          </Col>
        </Row>
      </div>
    </main>
  );
}

export default DesignsPage;
import React, { useEffect, useState } from "react";
import { Placeholder, Table } from "react-bootstrap";
import { useCookies } from "react-cookie";
import Utils from "../../configs/Utils";
import DesignListItem from "./designListItem";

const DesignList = ({...props}) => {
  const { setDesignUpdate, fetching, setFetching } = props;
  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cookie] = useCookies();

  useEffect(() => {
    const fetchDesigns = async () => {
      try {
        const designs = await Utils._apiRequest({
          path: 'design.all',
          type: 'GET',
          cookie
        });
        setLoading(false);
        setDesigns(designs);
        setFetching(false);
      } catch (err) {
        console.log(err.message);
      }
    }

    fetchDesigns();
    
    if (fetching) {
      fetchDesigns();
    }
  }, [fetching, setFetching, cookie]);

  return(
    <Table striped hover className="text-nowrap">
      <thead>
        <tr>
          <th width={50}>#</th>
          <th width={230}>Name</th>
          <th width={80}>Mockup</th>
          <th width={80}>Design</th>
          <th>Owner</th>
          <th>Workspace</th>
          <th>Created</th>
          <th width={50}>...</th>
        </tr>
      </thead>
      <tbody>
        { loading ? [1,2,3,4,5].map((i) => (
          <tr key={i}>
            <td>{i}</td>
            <Placeholder as="td" animation="glow">
              <Placeholder xs={10} />
            </Placeholder>
            <Placeholder as="td" animation="glow">
              <Placeholder xs={10} />
            </Placeholder>
            <Placeholder as="td" animation="glow">
              <Placeholder xs={10} />
            </Placeholder>
            <Placeholder as="td" animation="glow">
              <Placeholder xs={10} />
            </Placeholder>
            <Placeholder as="td" animation="glow">
              <Placeholder xs={10} />
            </Placeholder>
            <Placeholder as="td" animation="glow">
              <Placeholder xs={10} />
            </Placeholder>
            <td>...</td>
          </tr>
        )) : designs.length ? designs.map((design, index) => (
          <DesignListItem 
            key={design._id} 
            index={index} 
            design={design} 
            setDesignUpdate={setDesignUpdate} 
            designs={designs}
            setDesigns={setDesigns}
          />
        )) : (
          <tr>
            <td colSpan={8}>Nothing to show.</td>
          </tr>
        ) }
        
      </tbody>
    </Table>
  )
}

export default DesignList;
import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Utils from "../../configs/Utils";
import Orders from "../../Components/Orders";

const OrdersPage: React.FC = () => {
  useEffect(() => {
    Utils.setPageTitle('Orders page');
  }, []);

  return (
    <main className="page-main">
      <div className="main-container container">
        <div className="d-flex align-items-center p-3 my-3 text-white bg-primary rounded shadow-sm heading-box">
          <Image rounded src={Utils.getPublicImage('/assets/images/woo-section-icon-w.png')} width={38} className="me-3" alt="Stripe heading img" />
          <div className="lh-1">
            <h2 className="h6 mb-0 text-white lh-1">All Orders</h2>
            <small>Sync orders, ...</small>
          </div>
        </div>

        <Orders />
      </div>
    </main>
  );
}

export default OrdersPage;
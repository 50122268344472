import React, { useEffect } from 'react';

import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Config from '../../configs/Config';
import Utils from '../../configs/Utils';

import './policy.scss';

const PagePolicy: React.FC = () => {
  useEffect(() => {
    Utils.setPageTitle('Policy page');
  }, []);

  return (
    <>
      <div className="logo text-center" style={{ marginTop: 20 }}>
        <Link to="/" title={Config.app.name}>
          <Image src={Utils.getPublicImage('/assets/images/logo-main.png')} width={170} alt="" />
        </Link>
      </div>
      
      <div className='main-page policies-page'>
        <h1>Privacy Policy</h1>
        <p>Effective Date: 12 Jun, 2024</p>
        <p>At {Config.app.name}, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our services to manage orders and list products on TikTok Shop.</p>
        <h2>1. Information We Collect</h2>
        <h3>A. Personal Information</h3>
        <p>When you sign up for our services or create an account, we may collect the following personal information:</p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Business name</li>
          <li>Contact details</li>
        </ul>
        <h3>B. Business Information</h3>
        <p>To assist with managing your TikTok Shop, we may collect and store business-related information, including:</p>
        <ul>
          <li>Product listings (names, descriptions, prices, etc.)</li>
          <li>Order details (customer names, addresses, order contents)</li>
          <li>Shop performance metrics</li>
        </ul>
        <h3>C. Automatically Collected Information</h3>
        <p>We may collect information automatically when you use the app, such as:</p>
        <ul>
          <li>IP address</li>
          <li>Device information (browser type, version, operating system)</li>
          <li>Usage data (features used, time spent)</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>We use your information to:</p>
        <ul>
          <li>Provide and improve our services for managing orders and products on TikTok Shop.</li>
          <li>Process transactions and communicate with you about your account and activities.</li>
          <li>Personalize your experience and offer customer support.</li>
          <li>Analyze performance metrics to improve product features and functionality.</li>
        </ul>

        <h2>3. Data Sharing</h2>
        <p>We do not share your personal information with third parties, except in the following cases:</p>
        <ul>
          <li><strong>Service Providers</strong>: We may work with third-party providers to help manage and improve our services (e.g., hosting services, analytics tools). These providers only access your information to perform specific tasks on our behalf.</li>
          <li><strong>Legal Requirements</strong>: We may disclose your information when required by law, such as in response to a subpoena or similar legal process.</li>
          <li><strong>Business Transfers</strong>: If our company is involved in a merger, acquisition, or asset sale, your information may be transferred as part of the transaction.</li>
        </ul>

        <h2>4. Data Security</h2>
        <p>We take reasonable measures to protect your data from unauthorized access, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.</p>

        <h2>5. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access and update your personal information.</li>
          <li>Request deletion of your data, subject to legal obligations.</li>
          <li>Object to or restrict the processing of your personal information.</li>
        </ul>
        <p>To exercise these rights, please contact us at jason961301@gmail.com</p>

        <h2>6. Cookies and Tracking Technologies</h2>
        <p>We may use cookies and similar tracking technologies to enhance your experience. Cookies help us understand user behavior and improve our services. You can control cookie preferences through your browser settings.</p>
        
        <h2>7. Changes to This Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be reflected on this page with a new effective date. We encourage you to review the policy periodically.</p>

        <h2>8. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
        <ul>
          <li><strong>Email</strong>: jason961301@gmail.com</li>
          <li><strong>Address</strong>: 1942 Broadway St. STE 314C, Boulder, CO 80302, US</li>
        </ul>
      </div>
    </>
  );
}

export default PagePolicy;
import React from "react";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

const NotFound: React.FC = () => {
  return (
    <main className="page-main notFound-page container pt-5 pb-5 d-flex flex-column align-items-center justify-content-center">
      <h1 className="text-primary">Page Not Found</h1>
      <p style={{color: "#666"}}>Sorry, the page you&apos;re looking for doesn&apos;t exist.</p>
      <Button href="/"><FontAwesomeIcon icon={faArrowRightLong} className="me-2" />Go to Dashboard</Button>
    </main>
  );
}

export default NotFound;
import { gapi } from "gapi-script";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Utils from "../../configs/Utils";
import Enums from "../../configs/Enums";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogleDrive } from "@fortawesome/free-brands-svg-icons";

const GooglePicker = ({ oauth2Token, setOath2Token, sheetsList, setSheetsList }) => {
  let { access_token, expiry_date, refresh_token } = oauth2Token;
  const [cookie] = useCookies('');
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    gapi.load('picker', () => {
      console.log('Google Picker API loaded');
    });
  }, []);

  const openPicker = async () => {
    if (!access_token) return;
    setIsLoading(true);

    if (new Date().getTime() >= expiry_date) {
      const refreshRespose = await Utils._apiRequest({
        path: Enums.endpoints.google.oauth2Refresh,
        type: 'GET',
        query: { refresh_token },
        cookie
      });

      if (refreshRespose.oath2Token) {
        setOath2Token(refreshRespose.oath2Token);
      }
      if (refreshRespose.access_token) {
        access_token = refreshRespose.access_token;
        expiry_date = refreshRespose.expiry_date;
      }
    }

    const view = new window.google.picker.DocsView()
      .setIncludeFolders(true)  // Allows selection of folders
      .setSelectFolderEnabled(true);  // Enables the user to select folders

    const sheetsView = new window.google.picker.View(window.google.picker.ViewId.SPREADSHEETS);
      
    const picker = new window.google.picker.PickerBuilder()
      .addView(sheetsView)
      .addView(view)
      .setOAuthToken(access_token)
      .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY)
      .setCallback((data) => {
        pickerCallback(data);
      })
      .build();
    picker.setVisible(true);
  };

  const pickerCallback = async (data) => {
    if (data.action === window.google.picker.Action.PICKED) {
      const selected = data.docs[0];
      const payload = {
        key: selected.id,
        group: "tiktok_lising_sheets",
        values: selected
      };
      const optionResponse = await Utils._apiRequest({
        path: Enums.endpoints.options.SAVE,
        type: 'POST',
        payload: payload,
        cookie
      });
      let updatedList = [...sheetsList, optionResponse];
      updatedList = updatedList.filter((item, index) => {
        return updatedList.findIndex((iItem) => iItem.key === item.key) === index;
      });
      setSheetsList(updatedList);
      setIsLoading(false);
    } else if (data.action === window.google.picker.Action.CANCEL) {
      setIsLoading(false);
    }
  }

  return (
    <Button variant='dark' disabled={isLoading} className='f-w' style={{ padding: "0.4em 1.3em" }} onClick={openPicker} size='sm'>
      <FontAwesomeIcon icon={faGoogleDrive} className='me-2'/>
      Browse your Google Drive
    </Button>
  );
}

export default GooglePicker;
import React, { useEffect, useState } from "react";
import { Row, Col, Table } from 'react-bootstrap';
import StripeAccountList from "./StripeAccountList";
import StripeAccountForm from "./StripeAccountForm";
import Utils from "../../configs/Utils";

const StripeAccountsBlock = () => {
  const [accountList, setAccountList] = useState([]);
  const [updatingAccount, setUpdatingAccount] = useState({});
  const [accountDetail, setAccountDetail] = useState({});

  useEffect(() => {
    let detail = {};
    if (updatingAccount?.account) {
      detail['Account'] = updatingAccount.account
    }
    if (updatingAccount?.accountName) {
      detail['Account Name'] = updatingAccount.accountName
    }
    if (updatingAccount?.domain) {
      detail['Domain'] = updatingAccount.domain
    }
    if (updatingAccount?.status) {
      detail['Status'] = updatingAccount.status
    };
    if (updatingAccount?.updatedAt) {
      let dateFm = Utils.dateFormat(updatingAccount.updatedAt);
      detail['Updated At'] = dateFm;
    }

    if (updatingAccount?.meta?.payout?.interval) {
      detail['Payout Interval'] = updatingAccount.meta.payout.interval;
    }
    if (updatingAccount?.meta?.payout?.delay_days) {
      detail['Payout Delay'] = updatingAccount.meta.payout.delay_days + ' days';
    }


    setAccountDetail(detail);
  }, [updatingAccount]);

  return (
    <>
    <Row>
      <Col md={12}>
        <div className="my-3 p-3 bg-body rounded shadow-sm feature-box">
          <h6 className="heading border-bottom pb-2 mb-0">Stripe Accounts</h6>
          <StripeAccountList accountList={accountList} setAccountList={setAccountList} updatingAccount={updatingAccount} setUpdatingAccount={setUpdatingAccount} />
        </div>
      </Col>
    </Row>
    <Row>
      <Col md={5}>
        <StripeAccountForm setAccountList={setAccountList} updatingAccount={updatingAccount} setUpdatingAccount={setUpdatingAccount} />
      </Col>
      <Col md={7}>
        <div className="my-3 p-3 bg-body rounded shadow-sm feature-box">
          <h6 className="heading border-bottom pb-2 mb-0">Stripe Details</h6>
          <Table striped size="sm" borderless>
            <tbody>
              { Object.keys(accountDetail).map((key) => {
                return (
                  <tr key={key}>
                    <th className="text-end pe-4">{ key }</th>
                    <td>{ accountDetail[key] }</td>
                  </tr>
                )
              }) }
              
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
    </>
  );
}

export default StripeAccountsBlock;
import React, { useEffect, useState } from "react";
import { faCheck, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form} from "react-bootstrap";
import { useCookies } from "react-cookie";
import Utils from "../../configs/Utils";

interface QuickEditProps {
  path: string;
  pathParams?: Record<string, any>;
  param: string;
  defaultValue: string;
  onUpdate?: (param: string, value: string) => void;
}

const QuickEdit: React.FC<QuickEditProps> = ({ 
  path, 
  pathParams, 
  param, 
  defaultValue,
  onUpdate = () => {} 
}) => {
  const [cookie] = useCookies([]);
  const [value, setValue] = useState<string>('');
  const [process, setProcess] = useState<'off' | 'open' | 'loading'>('off');

  useEffect(() => {
    setValue(defaultValue);
  }, []);

  const onDoubleClickHandle = () => {
    setProcess('open');
  }

  const onSubmitHandle = async (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setProcess('loading');
    if (value.toString().trim().length) {
      try {
        const payload: Record<string, any> = {};
        payload[param] = value;
        const options = {
          type: 'put',
          path: path,
          cookie,
          payload: payload,
          ...(pathParams && { pathParams })
        };
        
        const data = await Utils._apiRequest(options);
        if (data.errors?.message) {
          throw new Error(data.errors.message);
        } else {
          setValue(data[param]);
          setProcess('off');
          if (onUpdate) {
            onUpdate(param, value);
          }
        }
      } catch (error) {
        alert((error as Error).message);
        setProcess('open');
      }
      
    }
  }

  return (
    <>
      { process !== 'off' ? (
        <div style={{ maxWidth: "500px" }}>
          <Form onSubmit={onSubmitHandle} className="d-flex align-items-center gap-2 mt-1 mb-1">
            <Form.Control
              disabled={process === 'loading'}
              size="sm"
              className="mb-0"
              defaultValue={ defaultValue }
              onChange={(e) => { setValue(e.target.value) }}
            />
            <Button size="sm" disabled={process === 'loading'} type="submit" className="mb-0"><FontAwesomeIcon icon={faCheck} /></Button>
          </Form>
        </div>
      ) : (
        <span style={{cursor: "pointer"}} className="quickEdit" onDoubleClick={onDoubleClickHandle}>
          { value }<FontAwesomeIcon className="ms-2" icon={faPen} size="xs" />
        </span>
      ) }
    </>
  );
}

export default QuickEdit;
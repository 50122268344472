import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useCookies } from "react-cookie";

import Utils from "../../configs/Utils";
import Loading from "../Common/Loading";
import DesignUploader from "./designUploader";
import { DesignProps } from "../../Models/Design";
import { workspaceProps } from "../../Models/Workspace";

interface DesignFormProps {
  designUpdate: DesignProps | null,
  setDesignUpdate: React.Dispatch<React.SetStateAction<DesignProps | null>>;
  setFetching: React.Dispatch<React.SetStateAction<boolean>>;
}

const DesignForm: React.FC<DesignFormProps> = ({ designUpdate, setDesignUpdate, setFetching }) => {
  // Define states
  const [cookie] = useCookies(['token']);
  const [name, setName] = useState<string>('');
  const [workspaces, setWorkspaces] = useState<workspaceProps[]>([]);
  const [workspace, setWorkspace] = useState<string>('');
  const [formLoading, setFormLoading] = useState<boolean>(true);
  
  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        const wspaces = await Utils._apiRequest({
          path: 'workspace.all',
          type: 'GET',
          cookie
        });
        setWorkspaces(wspaces);
        setFormLoading(false);
      } catch(error) {
        console.log((error as Error).message);
      }
    }

    fetchWorkspaces();
  }, []);

  const submitHandle = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormLoading(true);

    try {
      if (!designUpdate?._id) {
        const design = await Utils._apiRequest({
          path: "design.all",
          type: 'POST',
          payload: { name, workspace },
          cookie
        });
        setDesignUpdate(design);
      } else {
        setDesignUpdate(null);
        setFetching(true);
      }
      setFormLoading(false);
      setFetching(true);
    } catch(error) {
      console.log((error as Error).message);
      setFormLoading(false);
    }
  }

  return (
    <Form onSubmit={(e) => submitHandle(e)} className={`my-3 p-3 p-md-4 border rounded-3 bg-body-tertiary${ formLoading ? ' loading' : '' }`}>
      <Loading show={formLoading} />
      { !designUpdate?._id ? (
        <>
        <Form.Group className="mb-3" controlId="designFormWorkspace-field">
          <Form.Label>Workspace</Form.Label>
          <Form.Select size="sm" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setWorkspace(e.target.value)}>
            <option>- Select the workspace -</option>
            { workspaces.length ? 
              workspaces.map((wsp) => (
                <option key={wsp._id} value={wsp._id}>{ wsp.name }</option>
              )) 
            : (
              <></>
            ) }
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="designFormName-field">
          <Form.Label>Design Name</Form.Label>
          <Form.Control size="sm" type="text" placeholder="Product SKU..." onChange={(e) => setName(e.target.value)} />
        </Form.Group>
        
        <Button size="sm" className="w-100" type="submit" variant="dark">Next Step</Button>
        </>
      ) : (
        <>
        <Form.Group className="mb-3" controlId="designFormMockup-field">
          <Form.Label>Mockup</Form.Label>
          <DesignUploader field="mockup" designUpdate={designUpdate} setDesignUpdate={setDesignUpdate} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="designFormDesign-field">
          <Form.Label>Design</Form.Label>
          <DesignUploader field="design" designUpdate={designUpdate} setDesignUpdate={setDesignUpdate} />
        </Form.Group>
        
        <Button size="sm" className="w-100" type="submit" variant="primary">Done</Button>
        </>
      ) }
    </Form>
  )
}

export default DesignForm;
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

interface PaginationProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  count: number;
  limit: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const Pagination: React.FC<PaginationProps> = ({...props}) => {
  const {
    page,
    setPage,
    count,
    limit,
    setLoading
  } = props;

  const navigate = useNavigate();

  const handlePaginationClick = (e: React.MouseEvent, type = 'next') => {
    e.preventDefault();
    let newPage = page;
    switch(type) {
      case "first":
        newPage = 1;
        break;
      case "last":
        newPage = Math.ceil(count / limit);
        break;
      case 'prev':
        newPage--;
        break;
      default:
        newPage++;
    }
    setPage(newPage);
    setLoading(true);

    // update query string
    const newParams = new URLSearchParams(window.location.search);
    newParams.set('page', newPage.toString());
    navigate(`?${newParams.toString()}`);
  }

  return (
    <>
    { count > 0 ? (
      <ButtonToolbar className="mb-3 justify-content-between align-items-center" aria-label="Toolbar with Button groups">
        <span className="me-2">Show <strong>{(page-1)*limit + 1} - {Math.min(page*limit, count)}</strong> of <strong>{count}</strong> Items</span>
        
        <ButtonGroup className="me-2" aria-label="Pagination buttons">
          <Button size="sm" disabled={ page === 1 ? true : false } variant="secondary" onClick={(e) => handlePaginationClick(e, 'first')}><FontAwesomeIcon icon={faAnglesLeft} /></Button>
          <Button size="sm" disabled={ page === 1 ? true : false } variant="secondary" onClick={(e) => handlePaginationClick(e, 'prev')}><FontAwesomeIcon icon={faAngleLeft} /></Button>
          <Button size="sm" disabled={ (page*limit) >= count ? true : false } variant="secondary" onClick={(e) => handlePaginationClick(e, 'next')}><FontAwesomeIcon icon={faAngleRight} /></Button>
          <Button size="sm" disabled={ (page*limit) >= count ? true : false } variant="secondary" onClick={(e) => handlePaginationClick(e, 'last')}><FontAwesomeIcon icon={faAnglesRight} /></Button>
        </ButtonGroup>
      </ButtonToolbar>
    ): (
      <></>
    )}
    </>
  );
}

export default Pagination;
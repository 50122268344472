import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { NavDropdown, Image, Placeholder, Offcanvas, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBasketShopping, faGaugeHigh, faObjectGroup, faRightFromBracket, faShop, faSliders, faUser } from "@fortawesome/free-solid-svg-icons";

import { useAuth } from '../../contexts/AuthContext';
import Utils from '../../configs/Utils';

import './style.scss';

const PageHeader: React.FC = () => {
  // Define states
  const [cookie, , removeCookie] = useCookies(['token']);
  const { currentUser, setCurrentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isRender, setIsRender] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setIsRender(cookie?.token);
  }, [cookie]);

  const logoutClickHandle = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    try {
      removeCookie('token', { path: '/' });
      setCurrentUser(null);
      // window.location.href = '/';
      navigate('/');
    } catch (err) {
      console.log('request Auth error: ', err);
    }
  }

  const handleShow = () => {
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  return (
    <>
    { isRender && 
      <>
      <header className="main-header">
        <nav className="navbar navbar-expand-lg navbar-dark pt-0 pb-0">
          <div className="container">
            <Button className="mobile-menu-btn d-block d-md-none" onClick={handleShow}>
              <FontAwesomeIcon icon={faBars} />
            </Button>
            <Link className='navbar-brand pt-3 pb-3' to='/'><Image src={Utils.getPublicImage('/assets/images/logo-main.png')} width={105} /></Link>
            <div className="d-none d-md-flex navbar-collapse offcanvas-collapse ms-4 align-items-stretch">
              <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                <li className='nav-item'>
                  <Link className={`nav-link${location.pathname === '/'? ' active' : ''}`} to="/">
                    <FontAwesomeIcon icon={faGaugeHigh} size="xl" className='d-block mx-auto mb-1' />
                    Dashboard
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className={`nav-link${ location.pathname.includes('/stores') ? ' active' : '' }`} to="/stores">
                    <FontAwesomeIcon icon={faShop} size="xl" className='bi d-block mx-auto mb-1' />Stores
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className={`nav-link${location.pathname === '/orders'? ' active' : ''}`} to="/orders">
                    <FontAwesomeIcon icon={faBasketShopping} size="xl" className='d-block mx-auto mb-1' />Orders
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className={`nav-link${location.pathname === '/designs'? ' active' : ''}`} to="/designs">
                    <FontAwesomeIcon icon={faObjectGroup} size="xl" className='d-block mx-auto mb-1' />Designs
                  </Link>
                </li>
              </ul>
            </div>

            <NavDropdown id="account-dropdown" className='d-flex align-items-center account-dropdown' align="end" menuVariant="dark" title={
              (
                <>
                <Image src={Utils.getPublicImage('/assets/images/profile-icon-new.png')} roundedCircle width={22} className='me-2' />
                <span className='d-none d-md-block'>
                  { currentUser?._id ? currentUser.fullName : (
                    <Placeholder animation="glow">
                      <Placeholder style={{ width: "75px", display: "inline-block" }} />
                    </Placeholder>
                  ) }
                </span>
                </>
              )
            }>
              <NavDropdown.Item href="#"><FontAwesomeIcon icon={faUser} />Profile</NavDropdown.Item>
              <NavDropdown.Item onClick={() => { navigate('/settings'); }}><FontAwesomeIcon icon={faSliders} />Settings</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#" onClick={logoutClickHandle}><FontAwesomeIcon icon={faRightFromBracket} />Logout</NavDropdown.Item>
            </NavDropdown>
          </div>
        </nav>
      </header>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>MENU</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="nav nav-pills flex-column mb-auto">
            <li className='nav-item'>
              <Link className={`nav-link${location.pathname === '/'? ' active' : ''}`} to="/" onClick={handleClose}>
                <FontAwesomeIcon icon={faGaugeHigh} size="xl" className='bi pe-none me-2' />
                Dashboard
              </Link>
            </li>
            <li className='nav-item'>
              <Link className={`nav-link${ location.pathname.includes('/stores') ? ' active' : '' }`} to="/stores" onClick={handleClose}>
                <FontAwesomeIcon icon={faShop} size="xl" className='bi pe-none me-2' />Stores
              </Link>
            </li>
            <li className='nav-item'>
              <Link className={`nav-link${location.pathname === '/orders'? ' active' : ''}`} to="/orders" onClick={handleClose}>
                <FontAwesomeIcon icon={faBasketShopping} size="xl" className='bi pe-none me-2' />Orders
              </Link>
            </li>
            <li className='nav-item'>
              <Link className={`nav-link${location.pathname === '/designs'? ' active' : ''}`} to="/designs" onClick={handleClose}>
                <FontAwesomeIcon icon={faObjectGroup} size="xl" className='bi pe-none me-2' />Designs
              </Link>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      </>
    }
    </>
    
  );
}

export default PageHeader;
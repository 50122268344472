import React, { useEffect, useState } from "react";
import { Placeholder, Table } from "react-bootstrap";
import Utils from "../../configs/Utils";
import { useCookies } from "react-cookie";
import OrderedListItem from "./orderedListItem";
import OrderDetailPopup from "./orderDetailPopup";
import Enums from "../../configs/Enums";
import { useAuth } from "../../contexts/AuthContext";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const OrderedList = ({...props}) => {
  const {
    orders, 
    setOrders, 
    setCountOrders,
    page,
    loading,
    setLoading,
    filter
  } = props;

  const [cookie] = useCookies(['']);
  const [popupShow, setPopupShow] = useState(false);
  const [popupData, setPopupData] = useState({});
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const orders = await Utils._apiRequest({
          path: Enums.endpoints.orders.ALL,
          query: filter,
          query: {
            page: page
          },
          type: 'GET',
          cookie
        });
        setOrders(orders.data);
        setCountOrders(orders.count);
        setLoading(false);
      } catch(err) {
        console.log(err.message);
      }
    }

    setLoading(true);
    fetchOrders();
  }, [page, filter]);

  return(
    <>
    <OverlayScrollbarsComponent
      style={{ maxWidth: '100%' }}
      options={{ scrollbars: { autoHide: 'scroll' } }}
    >
      <Table striped hover className="text-nowrap table-text-inline">
        <thead>
          <tr>
            <th width={30}>#</th>
            <th width={80}>Thumb</th>
            <th width={100}>Order Number</th>
            <th width={100}>Status</th>
            <th>Billing Name</th>
            <th width={50}>Items</th>
            <th width={100}>Price</th>
            <th>Shop name</th>
            { currentUser?.admin ? <th>Seller</th> : <></> }
            <th width={150}>Created</th>
            <th width={50}>...</th>
          </tr>
        </thead>
        <tbody>
          { loading ? (
            <>
            { [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map((i) => {
              return (
                <tr key={i} className="placeholder-group">
                  <td>{i}</td>
                  <td>
                    <Placeholder animation="glow">
                      <div className="orderListItem-thumb"></div>
                    </Placeholder>
                  </td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={ Utils.mdRand(4, 11)} />
                    </Placeholder>
                  </td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={ Utils.mdRand(5, 10)} />
                    </Placeholder>
                  </td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={ Utils.mdRand(4, 7)} />
                    </Placeholder>
                  </td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  </td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={11} />
                    </Placeholder>
                  </td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={ Utils.mdRand(8, 11)} />
                    </Placeholder>
                  </td>
                  { currentUser?.admin ? (
                    <td>
                      <Placeholder animation="glow">
                        <Placeholder xs={ Utils.mdRand(8, 11)} />
                      </Placeholder>
                    </td>
                  ) : <></> }
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={10} />
                    </Placeholder>
                  </td>
                  <td>
                    ...
                  </td>
                </tr>
              )
            })}
            </>
          ) : orders.length ? 
            orders.map((item, i) => {
              return (
                <OrderedListItem 
                  key={i} 
                  index={(i+1)} 
                  id={item._id}
                  order={item.data} 
                  store={item.store} 
                  seller={item.user}
                  setPopupShow={setPopupShow}
                  setPopupData={setPopupData} 
                />
              )
            }) 
          : (
            <tr>
              <td colSpan={10}><i>Nothing to show...</i></td>
            </tr>
          ) }
        </tbody>
      </Table>
    </OverlayScrollbarsComponent>
      
    <OrderDetailPopup popupShow={popupShow} setPopupShow={setPopupShow} popupData={popupData} />
    </>
  );
}

export default OrderedList;
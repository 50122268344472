import React, { useState } from "react";
import { Button, Image } from "react-bootstrap";
import Utils from "../../configs/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useCookies } from "react-cookie";

const DesignListItem = ({...props}) => {
  const { index, design, setDesignUpdate, designs, setDesigns } = props;
  const [loading, setLoading] = useState(false);
  const [cookie] = useCookies(['']);

  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    const id = design._id;
    const designDel = await Utils._apiRequest({
      path: 'design.del',
      pathParams: { id },
      type: 'DELETE',
      cookie
    });

    const newDesigns = designs.filter(ds => {
      return ds._id !== designDel._id;
    });

    setDesigns(newDesigns);
  }

  return (
    <tr className={`list-item${ loading ? ' loading' : '' }`}>
      <td>{index+1}</td>
      <td>{ design.name }</td>
      <td>
        <Image width={50} height={50} className="img-cover" rounded src={ design.mockup?.url || Utils.getPublicImage('/assets/images/img-placeholder-x150.png') } />
      </td>
      <td><Image width={50} height={50} className="img-cover" rounded src={ design.design?.url || Utils.getPublicImage('/assets/images/img-placeholder-x150.png') } /></td>
      <td>{ design.owner.fullName }</td>
      <td>{ design.workspace.name }</td>
      <td>{ Utils.dateFormat(design.createdAt) }</td>
      <td>
        <Button className="ms-1" size="xs" variant="secondary" onClick={(e) => setDesignUpdate(design)} title="Edit Design"><FontAwesomeIcon icon={faPen} /></Button>
        <Button className="ms-1" size="xs" variant="danger" onClick={(e) => handleDelete(e)} title="Delete Design"><FontAwesomeIcon icon={faTrashCan} /></Button>
      </td>
    </tr>
  );
}

export default DesignListItem;
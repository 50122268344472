import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import Utils from '../../configs/Utils';
import { useCookies } from 'react-cookie';

const AuthStore = () => {
  const [searchParams] = useSearchParams();
  const [cookie, setCookie] = useCookies();
  const [, setTokenData] = useState({});
  const [, setShopData] = useState({});
  const navigate = useNavigate();

  const [heading, setHeading] = useState(null);
  const [message, setMessage] = useState(null);

  const auth_code = searchParams.get('code');
  const app_key = searchParams.get('app_key');
  let state = searchParams.get('state');

  useEffect(() => {
    Utils.setPageTitle('Auth Store page');
  }, []);

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        const result = await Utils._apiRequest({
          path: '/tiktok/auth',
          type: 'POST',
          payload: { auth_code, app_key }
        });
        setTokenData(result);

        if (result && result.data) {
          console.log(result);
          let pareData = result.data;
          pareData.user = state;
          fetchAuthShop(pareData);
        } else {
          throw new Error('We can not verify your auth code, please try re-Auth againt!');
        }
      } catch(err) {
        console.log(err.message);
        setHeading('Oops!');
        setMessage(err.message);
      }
    }

    const fetchAuthShop = async (payload) => {
      try {
        const result = await Utils._apiRequest({
          path: '/tiktok/auth/shop',
          type: 'POST',
          payload: payload
        });
        setShopData(result);
        if(result._id) {
          // navigate('/stores/'+result._id);
          setHeading('Congratulations!');
          setMessage(`You have successfully connected your TikTok Shop account with Onealdern.com. Please return to the stores page to verify your information.`);
        } else {
          throw new Error(`User state not found. We're unable to verify your account!`);
        }
        console.log(result);
      } catch(err) {
        console.error(err);
        alert(err.message);
        setHeading('Oops!');
        setMessage(err.message);
      }
    }

    if (state) {
      fetchAuth();
    } else {
      setHeading('Oops!');
      setMessage(`User state not found. We're unable to verify your account!`);
    }

  }, []);

  return (
    <main className='main-page container mt-5'>
      <div className="p-5 mb-4 bg-body-tertiary rounded-3 shadow" style={{maxWidth: "850px", margin: 'auto', fontSize: '130%'}}>        
        <div className='row text-center'>
          { heading ? (
            <h2>{heading}</h2>
          ) : (
            <Placeholder as="h2" animation="glow">
              <Placeholder xs={4} />
            </Placeholder>
          ) }
          
          { message ? (
            <p className='mb-0'>{message}</p>
          ) : (
            <>
            <Placeholder as="span" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
            <Placeholder as="span" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
              <Placeholder xs={5} />
            </Placeholder>
            </>
          ) }
        </div>
      </div>
      
    </main>
  );
}

export default AuthStore;
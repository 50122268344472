import React from 'react';

const PageFooter: React.FC = () => {
  const year = new Date().getFullYear();
  return (
    <footer className='main-footer'>
      <span className='copy-right'>&copy; { year } <strong>{ process.env.REACT_APP_TITLE }</strong>. All Rights Reserved.</span>
    </footer>
  );
}

export default PageFooter;
import React, { useState } from "react";
import { Button, Form } from 'react-bootstrap';
import Loading from "../../Common/Loading";
import Config from "../../../configs/Config";
import { useAuth } from "../../../contexts/AuthContext";
import { useCookies } from "react-cookie";
import Utils from "../../../configs/Utils";
import Enums from "../../../configs/Enums";

const WoocommerceStoreForm = () => {
  const { currentUser } = useAuth();
  const [cookie] = useCookies(['']);

  const [formLoading, setFormLoading] = useState();

  const [storeId, setStoreId] = useState('');
  
  const [storeName, setStoreName] = useState('');
  const [storeNameErr, setStoreNameErr] = useState('');

  const [domain, setDomain] = useState('');
  const [domainErr, setDomainErr] = useState('');

  const validForm = () => {
    if (domain.length === 0) {
      setDomainErr('That field is required!');
    }
  }

  const openWooAuthPopup = (store_id) => {
    setDomain(domain.replace(/\/+$/, ''));
    const params = new URLSearchParams();
    params.append('app_name', Config.app.name);
    params.append('scope', 'read_write');
    params.append('user_id', store_id);
    params.append('return_url', window.location.href);
    params.append('callback_url', Config.getApiURL(Enums.endpoints.store.WOO_AUTH));

    const authUrl = domain + '/wc-auth/v1/authorize?' + params.toString();
    window.popupWindow = window.location.replace(authUrl);
  }

  const submitHandle = async (e) => {
    e.preventDefault();
    validForm();
    setFormLoading(true);
    setDomain(domain.replace(/\/+$/, ''));

    let requestParams = {
      payload: { storeName, domain: domain },
      path: Enums.endpoints.store.WOO,
      type: 'POST'
    };

    const data = await Utils._apiRequest({
      ...requestParams, 
      cookie
    });

    console.log(data);

    if (data.errors) {
      if (data.errors.storeName && data.errors.storeName !== '') {
        setStoreNameErr(data.errors.storeName);
      }
      if (data.errors.domain && data.errors.domain !== '') {
        setDomainErr(data.errors.domain);
      }
    } else {
      // success
      setStoreId(data._id);
      console.log(data._id, storeId);
      openWooAuthPopup(data._id);
    }

    setFormLoading(false);
  }

  return (
    <Form onSubmit={submitHandle} className={`my-3 p-3 p-md-4 border rounded-3 bg-body-tertiary${ formLoading ? ' loading' : '' }`}>
      <Loading show={formLoading} />
      
      <Form.Group className="mb-3" controlId="woocommerceStoreName">
        <Form.Label>Store Name</Form.Label>
        <Form.Control type="text" size="sm" placeholder="..." value={storeName} className={storeNameErr !== "" ? " is-invalid" : ""} onChange={(e) => {setStoreName(e.target.value); setStoreNameErr('')}} />
        { storeNameErr !== "" ? <p className="invalid-feedback">{ storeNameErr }</p> : null }
      </Form.Group>

      <Form.Group className="mb-3" controlId="woocommerceDomainField">
        <Form.Label>Domain URL (*)</Form.Label>
        <Form.Control type="text" size="sm" placeholder="https://domain.com" value={domain} className={domainErr !== "" ? " is-invalid" : ""} onChange={(e) => {setDomain(e.target.value); setDomainErr('')}} />
        { domainErr !== "" ? <p className="invalid-feedback">{ domainErr }</p> : null }
      </Form.Group>

      <Button type="submit" size="sm" className="me-2 w-100" variant="primary">Connect</Button>

    </Form>
  );
}

export default WoocommerceStoreForm;
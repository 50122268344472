import React from "react";
import { Spinner } from "react-bootstrap";

interface LoadingProps {
  show: boolean;
}

const Loading: React.FC<LoadingProps> = ({ show }) => {
  return (
    <>
      {show ? (
        <div className="loading-box">
          <Spinner animation="grow" variant="primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (<></>)}
    </>
  );
}

export default Loading;